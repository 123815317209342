import { useAppContext } from "../../context/appContext";
import { Input, InputCheckBox, InlineRadioPrice } from "../dashboard";

function FeesDiscounts({ optionsIndex }) {
  const {
    fees,
    discounts,
    options,
    // isLoading,
    handleArrayChange,
    createRecord,
    saveRecord,
  } = useAppContext();

  const handleSubmit = (e, paArray, paName) => {
    e.preventDefault();
    paArray.map(async (priceAdj, index) => {
      if (priceAdj.usePA) {
        if (!priceAdj.id) {
          console.log(`create warranty ${index}`);
          priceAdj.options_id = options[optionsIndex].id;
          const record = await createRecord(
            `/price-adj/create`,
            priceAdj,
            paName
          );
          handleArrayChange({
            baseName: "paName",
            baseArray: [...paArray],
            index,
            property: "id",
            value: record.id,
          });
        } else {
          console.log(`update price adjustment ${index}`);
          saveRecord(`/price-adj/update/${priceAdj.id}`, priceAdj, paName);
        }
      }
    });
  };
  const handleAmountInput = (e, index) => {
    const baseName = e.target.name.split(".")[0];
    const property = "amount";
    const absValue = Math.abs(e.target.value);
    console.log("absValue:", absValue);
    const value = baseName === "fees" ? absValue : -1 * absValue;
    const baseArray = baseName === "fees" ? [...fees] : [...discounts];
    handleArrayChange({ baseName, baseArray, index, property, value });
  };
  const handleDescInput = (e, index) => {
    const baseName = e.target.name.split(".")[0];
    const property = "description";
    const value = e.target.value;
    const baseArray = baseName === "fees" ? [...fees] : [...discounts];
    handleArrayChange({ baseName, baseArray, index, property, value });
  };
  const handleCheck = (e, index) => {
    const baseName = e.target.name.split(".")[0];
    const property = "usePA";
    const value = e.target.checked; // grab checked instead of value
    const baseArray = baseName === "fees" ? [...fees] : [...discounts];
    handleArrayChange({ baseName, baseArray, index, property, value });
  };
  const handleRadio = (e, index) => {
    const baseName = e.target.name.split(".")[0];
    const property = "modifier";
    const value = Number(e.target.value);
    const baseArray = baseName === "fees" ? [...fees] : [...discounts];
    handleArrayChange({ baseName, baseArray, index, property, value });
  };
  return (
    <>
      {/* ----- Fees ----- */}
      <div className="group one-third">
        <form
          onSubmit={(e) => handleSubmit(e, [...fees], "fees")}
          className="form"
        >
          <h3>Fees</h3>
          <div className="form-center fees">
            {fees.map((fee, index) => {
              return (
                <div
                  key={index}
                  className={
                    fee.usePA
                      ? "selected fee whole flex-parent"
                      : "fee whole flex-parent"
                  }
                >
                  <div className="one-half checkbox">
                    <InputCheckBox
                      name={`fees.amount${index}`}
                      checkName={`fees.usePA${index}`}
                      value={fee.usePA}
                      textValue={fee.amount || ""}
                      handleCheckboxChange={(e) => handleCheck(e, index)}
                      handleTextChange={(e) => {
                        handleAmountInput(e, index);
                      }}
                    />
                  </div>
                  <div className="one-half checkbox">
                    <InlineRadioPrice
                      name={`fees.modifier${index}`}
                      value={fee.modifier}
                      handleChange={(e) => {
                        handleRadio(e, index);
                      }}
                    />
                  </div>
                  <div className="whole no-label">
                    <Input
                      name={`fees.description${index}`}
                      labelText=""
                      value={fee.description || ""}
                      handleChange={(e) => {
                        handleDescInput(e, index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      </div>
      {/* ----- Discounts ----- */}
      <div className="group one-third">
        <form
          onSubmit={(e) => handleSubmit(e, [...discounts], "discounts")}
          className="form"
        >
          <h3>Discounts</h3>
          <div className="form-center discounts">
            {discounts.map((discount, index) => {
              return (
                <div
                  key={index}
                  className={
                    discount.usePA
                      ? "selected discount whole flex-parent"
                      : "discount whole flex-parent"
                  }
                >
                  <div className="one-half checkbox">
                    <InputCheckBox
                      name={`discounts.amount${index}`}
                      checkName={`discounts.usePA${index}`}
                      value={discount.usePA}
                      textValue={discount.amount || ""}
                      handleCheckboxChange={(e) => handleCheck(e, index)}
                      handleTextChange={(e) => {
                        handleAmountInput(e, index);
                      }}
                    />
                  </div>
                  <div className="one-half checkbox">
                    <InlineRadioPrice
                      name={`discounts.modifier${index}`}
                      value={discount.modifier}
                      handleChange={(e) => {
                        handleRadio(e, index);
                      }}
                    />
                  </div>
                  <div className="whole no-label">
                    <Input
                      name={`discounts.description${index}`}
                      labelText=""
                      value={discount.description || ""}
                      handleChange={(e) => {
                        handleDescInput(e, index);
                      }}
                    />
                  </div>
                </div>
              );
            })}
            {/* <button
              className="btn btn-block button"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? "please wait..." : "Save"}
            </button> */}
          </div>
        </form>
      </div>
    </>
  );
}
export default FeesDiscounts;
