import logo from "../assets/images/freelite-logo.png";
import logoLarge from "../assets/images/logo-large.png";

const Logo = () => {
  return <img src={logo} alt="freelite" className="logo" />;
};
const LargeLogo = () => {
  return <img src={logoLarge} alt="freelite" className="logo-large" />;
};

export { Logo, LargeLogo };
