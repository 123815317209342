import Wrapper from "../assets/wrappers/BigSidebar";
import { useAppContext } from "../context/appContext";
import NavLinks from "./NavLinks";
import { Logo } from ".";

function BigSidebar() {
  const { showSidebar, version, user } = useAppContext();
  // const location = useLocation();

  // const [showLogout, setShowLogout] = useState(false);

  // add show-sidebar class to display the sidebar
  let mainClassName = !showSidebar
    ? "sidebar-container show-sidebar"
    : "sidebar-container";
  // add sandbox class if selected
  mainClassName = user?.user_mode ? mainClassName + " sandbox" : mainClassName;

  return (
    <Wrapper>
      <div className={mainClassName}>
        <div className="content">
          <div className="logo-area">
            <Logo />
          </div>
          <NavLinks user={user} />
        </div>
        {/* <div className="account-dropdown">
          <button
            name="saveData"
            className="btn btn-block"
            type="button"
            onClick={handleSave}
            disabled={isLoading}
          >
            Save Data
          </button>
        </div> */}
        <div className="program">{`FreeLite JMS 3 v. ${version}`}</div>
      </div>
    </Wrapper>
  );
}
export default BigSidebar;
