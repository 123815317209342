import React, { useState, useEffect } from "react"
import { Logo, Alert } from "../components"
import Wrapper from "../assets/wrappers/LoginPage"
import { useAppContext } from "../context/appContext"
import { useNavigate } from "react-router-dom"
import { Input } from "../components/dashboard"

const initialState = {
  name: "",
  password: "",
  email: "",
}

function Register() {
  const [values, setValues] = useState(initialState)
  const { user, isLoading, showAlert, displayAlert, registerUser } = useAppContext()
  const navigate = useNavigate()

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    const { name, email, password } = values
    if (!name || !password) {
      displayAlert()
      return
    }
    const currentUser = { name, email, password }

    console.log("currentUser:", currentUser)
    registerUser(currentUser)
  }

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        navigate("/")
      }, 1200)
    }
  }, [user, navigate])

  return (
    <Wrapper className="full-page">
      <form className="form" onSubmit={handleSubmit}>
        <Logo />
        {showAlert && <Alert />}
        {/* name input */}
        <Input
          type="text"
          name="name"
          value={values.name}
          handleChange={handleChange}
          labelText="name"
        />
        {/* password input */}
        <Input
          type="text"
          name="password"
          value={values.password}
          handleChange={handleChange}
          labelText="password"
        />
        {/* email input */}
        <Input
          type="text"
          name="email"
          value={values.email}
          handleChange={handleChange}
          labelText="email"
        />
        <button type="submit" className="btn btn-block" disabled={isLoading}>
          Submit
        </button>
      </form>
    </Wrapper>
  )
}

export default Register
