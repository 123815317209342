import { useAppContext } from "../../context/appContext";
import { InputCheckBox } from "../dashboard";

function Warranties({ optionsIndex }) {
  const {
    warranties,
    options,
    // isLoading,
    handleArrayChange,
    createRecord,
    saveRecord,
  } = useAppContext();

  // check warranty and create/update
  const handleSubmit = (e) => {
    e.preventDefault();
    warranties.map(async (wrnty, index) => {
      if (wrnty.useWarranty) {
        if (!wrnty.id) {
          console.log(`create warranty ${index}`);
          wrnty.options_id = options[optionsIndex].id;
          const record = await createRecord(
            "/warranties/create",
            wrnty,
            "warranty"
          );
          handleArrayChange({
            baseName: "warranties",
            baseArray: [...warranties],
            index,
            property: "id",
            value: record.id,
          });
        } else {
          console.log(`update warranty ${index}`);
          saveRecord(`/warranties/update/${wrnty.id}`, wrnty, "warranty");
        }
      }
    });
  };
  const handleInput = (e, index) => {
    const property = "warranty";
    const value = e.target.value;
    const baseArray = [...warranties];
    const baseName = "warranties";
    handleArrayChange({ baseName, baseArray, index, property, value });
  };
  const handleCheck = (e, index) => {
    const property = "useWarranty";
    const value = e.target.checked; // grab checked instead of value
    const baseArray = [...warranties];
    const baseName = "warranties";
    handleArrayChange({ baseName, baseArray, index, property, value });
  };
  return (
    <div className="group one-third">
      <form onSubmit={handleSubmit} className="form">
        <h3>Warranties</h3>
        <div className="form-center warranties">
          {/* Loop through and display the warranties */}
          {warranties?.map((wrnty, i) => {
            return (
              <div
                key={i}
                className={
                  wrnty.useWarranty
                    ? "selected whole checkbox"
                    : "whole checkbox"
                }
              >
                {/* {console.log("selectedWarranty:", wrntyIndex)} */}
                <InputCheckBox
                  name={`warranty-desc-${i}`}
                  checkName={`include-warranty-${i}`}
                  value={wrnty.useWarranty}
                  textValue={wrnty.warranty || ""}
                  handleCheckboxChange={(e) => handleCheck(e, i)}
                  handleTextChange={(e) => {
                    handleInput(e, i);
                  }}
                />
              </div>
            );
          })}
          {/* <button className="btn btn-block form-row" type="submit" disabled={isLoading}>
            Save
          </button> */}
        </div>
      </form>
    </div>
  );
}
export default Warranties;
