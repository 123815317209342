// removes $ and converts variable type to number
const moneyToNumber = (moneyString) => {
  // return value if already a number
  if (typeof moneyString === "number") {
    return moneyString;
  }
  let newString = moneyString?.replace("$", "");
  newString = newString?.replace(",", "");
  return Number(newString);
};
// adds styling and trims/pads to two decimal places (40 => $40.00)
const numberToMoney = (moneyAmount) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return formatter.format(moneyAmount);
};

export { moneyToNumber, numberToMoney };
