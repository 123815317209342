import Contact from "./Contact";
import { useState } from "react";
import { useAppContext } from "../../context/appContext";

function Contacts() {
  const {
    contacts,
    customer,
    user,
    isLoading,
    displayAlert,
    getArrayRecord,
    handleArrayChanges,
    handlePrimativeChange,
    createRecord,
    saveRecord,
  } = useAppContext();

  const maxUserRolesId = 3; // service rep

  const handleSubmit = async (e) => {
    e.preventDefault();
    const allValuesPresent = contacts.every((contact) => contact.contact_name);
    if (!allValuesPresent) {
      displayAlert("Please include a name for every contact");
      return;
    }
    // save contacts
    for (const [index, contact] of contacts.entries()) {
      // create new contact if needed
      if (!contact.id) {
        const record = await createRecord(
          "/contact/create",
          contact,
          "Contact"
        );
      } else {
        // update existing contact
        await saveRecord(`/contact/update/${contact.id}`, contact, "Contact");
      }
    } // );
    // update state
    await getArrayRecord(`/contact/${customer.id}`, "contacts");
  };
  const addNewContact = async (e) => {
    e.preventDefault();
    if (!customer.id) {
      displayAlert("Please select a customer");
      return;
    }
    handleArrayChanges({
      baseName: "contacts",
      baseArray: [...contacts],
      index: contacts.length || 0,
      newValues: { customer_id: customer.id, is_active: true },
    });
  };
  const removeContact = (e) => {
    e.preventDefault();
    const lastContact = contacts.length - 1;
    const fewerContacts = [...contacts].slice(0, lastContact);

    handlePrimativeChange({
      property: "contacts",
      value: fewerContacts,
    });
  };

  const newestContact = contacts[contacts?.length - 1]?.contact_name;

  return (
    <div className="group one-third">
      <form onSubmit={handleSubmit} className="form form-center flex-parent">
        <h3>Contacts</h3>
        <div className="subform contacts">
          {contacts.length > 0
            ? // display the contacts in reverse order
              contacts.map((person, index) => {
                return (
                  <Contact key={index} index={contacts.length - index - 1} />
                );
              })
            : ""}
          {/* {contacts.length > 0
            ? contacts.map((person, index) => {
                return <Contact key={index} index={index} />;
              })
            : ""} */}
        </div>
        {user.user_roles_id <= maxUserRolesId && (
          <>
            <div className="one-half">
              <button
                name="saveContacts"
                className="btn btn-block"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "please wait..." : "Save Contacts"}
              </button>
            </div>
            <div className="one-half">
              {!Boolean(newestContact) && contacts?.length > 0 ? (
                <button
                  name="removeContact"
                  className="btn btn-block red"
                  disabled={isLoading}
                  onClick={removeContact}
                >
                  {isLoading ? "please wait..." : "Remove Blank Contact"}
                </button>
              ) : (
                <button
                  name="newContact"
                  className="btn btn-block"
                  disabled={isLoading}
                  onClick={addNewContact}
                >
                  {isLoading ? "please wait..." : "New Contact"}
                </button>
              )}
            </div>
          </>
        )}
      </form>
    </div>
  );
}
export default Contacts;
