import Wrapper from "../assets/wrappers/Navbar";
import { useEffect } from "react"; // , useState
import { FaBars } from "react-icons/fa"; // , FaUserCircle, FaCaretDown
import { useAppContext } from "../context/appContext";
import { Select } from "./dashboard";
import { useLocation } from "react-router-dom";
import appSave from "../utils/saveFunctions";

function Navbar() {
  const {
    initialState,
    address,
    customer,
    contacts,
    employees,
    job,
    lists,
    defaults,
    options,
    warranties,
    fees,
    discounts,
    appointments,
    payments,
    indices,
    report,
    email,
    calendar,
    user,
    prices,
    getLists,
    getDefaults,
    getPrices,
    initialize,
    loadMainRecords,
    createRecord,
    getArrayRecord,
    saveRecord,
    deleteRecord,
    handleChanges,
    handleArrayChange,
    handleArrayChanges,
    isLoading,
    displayAlert,
    toggleSidebar,
    toggleReportForm,
    updateContactsList,
    loadJobOptions,
  } = useAppContext();
  // const [showLogout, setShowLogout] = useState(false);
  const location = useLocation();
  const maxUserRolesId = 3; // Service Rep or higher

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // load top job in list if none loaded
    if (lists.listJobNames && !job.id) {
      const jobId = lists.listJobNames[0].key;
      // getRecord(`/core-job/${jobId}`, "job", "job")
      loadMainRecords(jobId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lists]);

  const handleClear = () => {
    // resets all relevant state values by reloading the current job
    loadMainRecords(job.id);
  };
  const handleInput = async (e) => {
    // const name = e.target.name
    const jobId = e.target.value;
    // handleChange({ baseObject: job, name, jobId })
    loadMainRecords(jobId);
  };
  const handleSave = async () => {
    appSave({
      initialState,
      location,
      address,
      customer,
      contacts,
      employees,
      job,
      defaults,
      options,
      warranties,
      fees,
      discounts,
      appointments,
      payments,
      indices,
      report,
      email,
      calendar,
      user,
      createRecord,
      saveRecord,
      deleteRecord,
      handleChanges,
      handleArrayChange,
      handleArrayChanges,
      displayAlert,
      toggleReportForm,
      updateContactsList,
      loadJobOptions,
    });
  };

  return (
    <Wrapper>
      {/* ---Desktop/Tablet Header--- */}
      <div className="nav-center mobile-no-show">
        {/* Navigation Toggle Button */}
        <button className="toggle-btn" type="button" onClick={toggleSidebar}>
          <FaBars />
        </button>
        {/* Save Button */}
        {user?.user_roles_id <= maxUserRolesId ? (
          <button
            name="saveData"
            className="btn btn-block"
            type="button"
            onClick={handleSave}
            disabled={isLoading}
          >
            Save
          </button>
        ) : (
          ""
        )}
        {/* Jobs Select List Input */}
        <div className="select-job">
          <Select
            name="job.id"
            labelText=" "
            value={job.id}
            handleChange={(e) => handleInput(e)}
            options={lists && lists.listJobNames}
          />
        </div>
        {/* Clear Button */}
        <button
          name="clearChanges"
          className="btn btn-block red"
          type="button"
          onClick={handleClear}
          disabled={isLoading}
        >
          Clear Changes
        </button>
      </div>
      {/* ---Mobile Header--- */}
      <div className="mobile-only">
        <div className="whole nav-center flex-parent">
          {/* Navigation Toggle Button */}
          <div className="one-fourth form-row">
            <button
              className="toggle-btn"
              type="button"
              onClick={toggleSidebar}
            >
              <FaBars />
            </button>
          </div>
          {/* Jobs Select List Input */}
          <div className="select-job three-fourths">
            <Select
              name="mobile.job.id"
              labelText=" "
              value={job.id}
              handleChange={(e) => handleInput(e)}
              options={lists && lists.listJobNames}
            />
          </div>
          {/* Save Button */}
          {user?.user_roles_id <= maxUserRolesId ? (
            <div className="one-half header-btn">
              <button
                name="mobileSaveData"
                className="btn"
                type="button"
                onClick={handleSave}
                disabled={isLoading}
              >
                Save Data
              </button>
            </div>
          ) : (
            ""
          )}
          {/* Clear Button */}
          <div className="one-half header-btn">
            <button
              name="mobileClearChanges"
              className="btn red"
              type="button"
              onClick={handleClear}
              disabled={isLoading}
            >
              Clear Changes
            </button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
export default Navbar;
