import { useAppContext } from "../../context/appContext";
import { TextArea, Select } from "../../components/dashboard";
import {
  Address,
  Contacts,
  Customer,
  FileFolder,
} from "../../components/JobSummary";

function JobSummary() {
  const { job, contacts, lists, displayAlert, handleChange, saveRecord } =
    useAppContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!job.job_type_id || !job.job_status_id || !job.office_job_notes) {
      displayAlert();
      return;
    }
    saveRecord(`/core-job/update/${job.id}`, job, "job");
  };
  const handleInput = (e) => {
    const name = e.target.name;
    const value =
      name === "job.office_job_notes" ? e.target.value : Number(e.target.value);
    handleChange({ baseObject: job, name, value });
  };

  return (
    <div className="page">
      {/* ----- Job Info ------ */}
      <div className="group two-thirds">
        <form onSubmit={handleSubmit} className="form">
          <h3>Job Info</h3>
          <div className="form-center">
            <div className="one-half">
              <Select
                name="job.job_status_id"
                labelText="Job Status"
                value={job.job_status_id || "default"}
                handleChange={(e) => handleInput(e)}
                options={lists && lists.listJobStatus}
              />
            </div>
            <div className="one-half">
              <Select
                name="job.default_contact"
                labelText="Current Contact"
                value={job.default_contact}
                handleChange={(e) => handleInput(e)}
                options={
                  contacts &&
                  contacts
                    .filter((cnt) => cnt.id)
                    .filter((cnt) => cnt.is_active)
                    .map((cnt) => {
                      return { key: cnt.id, text: cnt.contact_name };
                    })
                }
              />
            </div>
            <div className="whole">
              <TextArea
                name="job.office_job_notes"
                labelText="Job Notes"
                value={job.office_job_notes || ""}
                handleChange={(e) => handleInput(e)}
              />
            </div>
            {/* <button
                className="btn btn-block"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "please wait..." : "Save"}
              </button> */}
          </div>
        </form>
      </div>
      {/* ----- Address ------ */}
      <Address />
      {/* ----- Customer ------ */}
      <Customer />
      {/* ----- Contacts ------ */}
      <Contacts />
      {/* ----- File Folder ------ */}
      <FileFolder />
    </div>
  );
}
export default JobSummary;
