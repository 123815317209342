import { useState } from "react";
import { Input, Select, Checkbox } from "../dashboard";
import { useAppContext } from "../../context/appContext";
import { CustomerMatchModal2 } from "../JobSummary/index";
function Customer() {
  const {
    handleChange,
    handleChanges,
    job,
    customer,
    user,
    isLoading,
    lists,
    clearChanges,
    clearArrayChanges,
    displayAlert,
    createRecord,
    saveRecord,
    getCustomerMatchInfo,
    getJobLists,
  } = useAppContext();

  const maxUserRolesId = 3; // service rep

  const [showForm, setShowForm] = useState(false);
  const [changeCustomer, setChangeCustomer] = useState(false);

  const toggleModal = () => {
    setShowForm(!showForm);
  };

  const handleInput = async (e, baseObject) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ baseObject, name, value });
    const match = lists?.customerNames?.find((customer) => {
      return customer.text === value;
    });
    if (match && changeCustomer) {
      // Get corresponding customer, contacts, and address matches
      await getCustomerMatchInfo(match.key);
      // check for matching addresses and list any as an option
      toggleModal();
    }
  };
  const handleCheckBox = (e, baseObject) => {
    const name = e.target.name;
    const value = e.target.checked; // grab checked instead of value
    handleChange({ baseObject, name, value });
  };

  const addCustomer = async (e) => {
    e.preventDefault();
    // Check for duplicate customer name
    const duplicateName = lists.customerNames?.filter(
      (cust) => cust.text === customer.customer_name
    );
    if (duplicateName.length > 0) {
      displayAlert("A customer with that name already exists.");
      return;
    }
    // Check for empty values
    if (!customer.customer_name) {
      displayAlert("Please add a customer name.");
      return;
    }
    if (customer.lead_id === "default") {
      displayAlert("Please select a lead source.");
      return;
    }
    // Make changes for new customer
    const record = await createRecord("/customer/create", customer, "Customer");
    console.log("record:", record);
    handleChange({
      baseObject: { ...customer },
      name: "customer.id",
      value: record.id,
    });
    // Update job with customer info
    handleChange({
      baseObject: { ...job },
      name: "job.customer_id",
      value: record.id,
    });
    const updatedJob = { ...job, customer_id: record.id };
    await saveRecord(`/core-job/update/${job.id}`, updatedJob, "job");
    // Update dynamic job and customer lists
    getJobLists();
    setChangeCustomer(false);
  };
  const clearCustomer = (e) => {
    e.preventDefault();
    setChangeCustomer(true);
    // clear customer
    let baseName = "customer";
    let defaultState = {
      customer_name: "",
      id: "",
      lead_id: "default",
      roofer_discount: false,
    };
    clearChanges({ baseName, defaultState });
    // clear contact info from summary and pick list
    baseName = "contacts";
    defaultState = [];
    clearArrayChanges({ baseName, defaultState });
    // reset ids in core job data
    let newValues = { customer_id: null, default_contact: null };
    handleChanges({ baseObject: job, baseName: "job", newValues });
  };

  return (
    <div className="group one-third">
      <form className="form">
        <h3>Customer</h3>
        <div className="form-center sub-form">
          <div className="whole">
            <Input
              type="text"
              name="customer.customer_name"
              labelText="Customer Name"
              value={customer.customer_name || ""}
              dataList={{
                id: "customer-names",
                options: lists && changeCustomer ? lists.customerNames : [],
              }}
              handleChange={(e) => handleInput(e, { ...customer })}
            />
          </div>
          <div className="whole">
            <Select
              options={lists && lists.listLeadSources}
              name="customer.lead_id"
              labelText="Lead Source"
              value={customer.lead_id}
              handleChange={(e) => handleInput(e, { ...customer })}
            />
          </div>
          <div className="whole checkbox">
            <Checkbox
              type="checkbox"
              name="customer.roofer_discount"
              labelText="Roofer Discount"
              checked={customer.roofer_discount || false}
              handleChange={(e) => handleCheckBox(e, { ...customer })}
            />
          </div>
          {user?.user_roles_id <= maxUserRolesId && (
            <div className="whole">
              {/* Display change customer or add customer button */}
              {customer.id ? (
                <button
                  name="clearCustomer"
                  className="btn btn-block btn-danger form-row"
                  type="button"
                  disabled={isLoading}
                  onClick={clearCustomer}
                >
                  {isLoading ? "please wait..." : "Change Customer"}
                </button>
              ) : (
                <button
                  name="addCustomer"
                  className="btn btn-block"
                  type="submit"
                  disabled={isLoading}
                  onClick={addCustomer}
                >
                  {isLoading ? "please wait..." : "Add"}
                </button>
              )}
            </div>
          )}
        </div>
      </form>
      {/* ----- Address Selection Modal ------ */}
      <div
        className={showForm ? "show-modal modal-container" : "modal-container"}
      >
        <div className="group">
          <CustomerMatchModal2 toggleModal={toggleModal} />
        </div>
      </div>
    </div>
  );
}
export default Customer;
