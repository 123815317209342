const calcJobItemTotal = ({ newEditItem, job, customer, prices, lists }) => {
  let base_price, unitPrice, total;
  let extras_price = 0;
  let veluxFlashing = 0;
  const priceSheet = job.price_sheets_id;
  const roofer = customer.roofer_discount;
  const workType = newEditItem.work_types_id;
  const quantity = newEditItem.quantity;
  const size = newEditItem.skylight_sizes_id;
  const frame = newEditItem.frame_types_id;
  const glazing = newEditItem.top_glazing_id;
  const material = newEditItem.glazing_materials_id;
  const extras = newEditItem.extras_ids;
  const depth = newEditItem.tube_depths_id;
  const use_custom_price = newEditItem.use_custom_price;
  const custom_price = newEditItem.custom_price;
  const work_description = newEditItem.work_description;

  if (process.env.NODE_ENV === "development") {
    console.log("--------------------");
    console.log("priceSheet:", priceSheet);
    console.log("roofer:", roofer);
    console.log("workType:", workType);
    console.log("quantity:", quantity);
    console.log("size:", size);
    console.log("frame:", frame);
    console.log("glazing:", glazing);
    console.log("material:", material);
    console.log("extras:", extras);
    console.log("depth:", depth);
    // console.log("sizeText:", sizeText);
    // console.log("extensions:", numExtensions);
  }
  // --- get pricing tables ---
  const standardPrice = (
    roofer ? prices.rooferSkylightPrices : prices.skylightPrices
  ).filter(
    (record) =>
      parseInt(record.price_sheets_id) === parseInt(priceSheet) &&
      parseInt(record.skylight_sizes_id) === parseInt(size)
  );
  const {
    domes_only,
    with_mill,
    with_bronze,
    sf_mill,
    sf_bronze,
    velux_clear,
    velux_white,
    sf_velux,
    reflash,
    velux_flashing,
  } = standardPrice[0] || {};
  // labor price table
  const { service_call, labor_hourly } = prices.laborPrices.filter(
    (price) => parseInt(price.price_sheets_id) === parseInt(priceSheet)
  )[0];
  // sun tunnels price table
  const tunnelPrice = (
    roofer ? prices.rooferSunTunnelPrices : prices.sunTunnelPrices
  ).filter(
    (record) =>
      parseInt(record.price_sheets_id) === parseInt(priceSheet) &&
      parseInt(record.skylight_sizes_id) === parseInt(size)
  );
  const { retail, labor_first, extension, retail_impact, retail_low_profile } =
    tunnelPrice[0] || {};
  // --- Service Call ---
  if (workType === 2) {
    base_price = service_call;
  }
  // --- Reflash/Reseal ---
  else if (workType === 4) {
    base_price = reflash;
    // flashing kit price
    veluxFlashing = velux_flashing;
    console.log("VELUX FLASHING 1:", velux_flashing);
  }
  // --- Sun tunnel base price ---
  else if (workType === 5) {
    // calculate unit cost
    let unitCost = retail;
    if (frame === 6) {
      unitCost = retail_low_profile;
    }
    if (frame === 7) {
      unitCost = retail_impact;
    }
    // calculate base install cost
    const sizeText = lists.listTubeDepths?.filter(
      (item) => item.id === depth
    )[0]?.text;
    const template = /\d{1} extensions*/;
    const match = sizeText?.match(template);
    const numExtensions = match ? Number(match[0].charAt(0)) : 0;
    base_price = unitCost + labor_first + extension * numExtensions;
  }
  //  --- Custom base price ---
  else if (workType === 6) {
    // base_price = "custom";
  }
  //  --- Hourly base price ---
  else if (workType === 13) {
    base_price = labor_hourly;
    console.log("setting labor price...", labor_hourly);
  }
  //  --- New Install price ---
  else if (workType === 14) {
    // base_price = "custom";
  }
  // --- All standard base prices ---
  else {
    // - Acrylic Dome Prices -
    if (material === 1) {
      // no frame
      if (frame === 100) {
        base_price = domes_only;
        console.log("no frame price:", domes_only);
      }
      // mill frame curb mount
      else if (frame === 1) {
        base_price = with_mill;
      }
      // painted frame curb mount
      else if (frame === 2) {
        base_price = with_bronze;
      }
      // mill frame self-flashing
      else if (frame === 3) {
        base_price = sf_mill;
      }
      // painted frame self-flashing
      else if (frame === 4) {
        base_price = sf_bronze;
      }
    }
    // velux curb mount
    if (frame === 9) {
      base_price = glazing === 3 ? velux_clear : velux_white;
    }
    // velux deck mount
    if (frame === 10) {
      base_price = glazing === 3 ? sf_velux : null;
    }
  }
  // --- Extras Prices ---
  let newDescription = work_description;
  // remove old line item descriptions
  const lineItemsStart = newDescription?.indexOf("\n\nLine Items:");
  if (lineItemsStart > -1) {
    newDescription = newDescription.slice(0, lineItemsStart);
  }
  // build new line item descriptions
  if (extras.length > 0) {
    newDescription += "\n\nLine Items:";
  }
  extras.forEach((extra) => {
    // extras.map((extra) => {
    let linePrice = 0;
    // get line item price for all standard extras
    if (extra !== 2) {
      const { price } = prices.extrasPrices.filter((record) => {
        return (
          parseInt(record.price_sheets_id) === parseInt(priceSheet) &&
          parseInt(record.extras_id) === parseInt(extra)
        );
      })[0]; // grab from the first (should be only) item in the array
      linePrice = price;
      // console.log("price:", price);
    }
    // get price for velux flashing kit
    else {
      console.log("VELUX FLASHING:", veluxFlashing);
      linePrice = veluxFlashing;
    }
    extras_price += linePrice;
    const extraDesc = lists.listExtras.filter((item) => item.id === extra)[0]
      .text;
    newDescription += `\n${extraDesc} - $${linePrice}.00`;
  });
  // --- Final Tally ---
  if (use_custom_price || base_price === "custom") {
    unitPrice = custom_price;
  } else {
    unitPrice = base_price + extras_price;
  }
  total = unitPrice * quantity;
  console.log("====================");
  console.log("use_custom_price", use_custom_price);
  console.log("custom_price", custom_price);
  console.log("base_price", base_price);
  console.log("extras_price", extras_price);
  console.log("total", total);
  console.log("work_description", newDescription);
  return {
    base_price,
    extras_price,
    unitPrice,
    total,
    work_description: newDescription,
  };
};

export default calcJobItemTotal;
