import { useState } from "react";
import { useAppContext } from "../../context/appContext";

import {
  AdminEmails,
  AdminWorkDesc,
  AdminCustomSettings,
  AdminFeesDiscounts,
  AdminPriceTables,
  AdminUsers,
} from "../../components/Admin";

function Admin() {
  const { isLoading } = useAppContext();
  const [menu, setMenu] = useState("email messages");
  const handleClick = (e) => {
    e.preventDefault();
    setMenu(e.target.name);
  };
  const buttons = [
    "fees/ Discounts/ Warranties",
    "email messages",
    "work descriptions",
    "custom settings",
    "price tables",
    "user management",
  ];
  return (
    <div className="page">
      {/* ----- Admin ------ */}
      <div className="group whole">
        <form className="form">
          <h3>Admin</h3>
          <div className="form-center">
            {buttons.map((title, index) => {
              return (
                <div className="one-third" key={index}>
                  <button
                    name={title}
                    className="btn btn-block"
                    type="button"
                    onClick={handleClick}
                    disabled={isLoading}
                  >
                    {isLoading ? "please wait..." : title}
                  </button>
                </div>
              );
            })}
          </div>
        </form>
      </div>

      {menu === "fees/ Discounts/ Warranties" ? <AdminFeesDiscounts /> : ""}
      {menu === "email messages" ? <AdminEmails /> : ""}
      {menu === "work descriptions" ? <AdminWorkDesc /> : ""}
      {menu === "custom settings" ? <AdminCustomSettings /> : ""}
      {menu === "price tables" ? <AdminPriceTables /> : ""}
      {menu === "user management" ? <AdminUsers /> : ""}
    </div>
  );
}
export default Admin;
