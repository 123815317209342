import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { Input, RadioPickButton, Select } from "../../components/dashboard";
import ConfirmBox from "../../components/ConfirmBox";
import { useEffect } from "react";

function UserSettings() {
  const {
    isLoading,
    logoutUser,
    saveRecord,
    user,
    lists,
    getJobLists,
    displayAlert,
    showConfirmBox,
    toggleConfirmBox,
    handleChange,
    handlePrimativeChange,
    loadMainRecords,
    changeUserMode,
  } = useAppContext();
  const { customerEmail, user_mode } = user;

  const maxUserRolesId = 0; // Developer only

  const listUserModes = [
    { id: 0, text: "Live" },
    { id: 1, text: "Sandbox" },
  ];

  const [newPassword, setNewPassword] = useState("");

  const loadCorrectMode = async () => {
    const localData = JSON.parse(localStorage.getItem("user", user));
    // console.log("localData.user_mode:", localData.user_mode);
    if (localData.user_mode) {
      console.log("attempting to load sandbox...");
    } else {
      console.log("attempting to load main db...");
    }
    const jobNamesList = await getJobLists();
    // console.log("jobNamesList:", jobNamesList);
    // console.log("jobNamesList[0].text:", jobNamesList[0]?.text);
    if (jobNamesList && jobNamesList[0]) {
      const jobId = jobNamesList[0].key;
      loadMainRecords(jobId);
    }
  };

  useEffect(() => {
    // load lists from correct database
    // console.log("changeUserMode after:", changeUserMode);
    if (changeUserMode) {
      loadCorrectMode();
      const property = "changeUserMode";
      const value = false;
      handlePrimativeChange({ property, value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.user_mode]);

  const handleModeChange = (e, number) => {
    // console.log("e.target.value:", e.target.value);
    // console.log("changeUserMode before:", changeUserMode);
    const userModeProperty = "changeUserMode";
    const userModeValue = true;
    handlePrimativeChange({ property: userModeProperty, value: userModeValue });
    // change mode in react state
    const baseObject = user;
    const name = "user.user_mode";
    const value = number ? Number(e.target.value) : e.target.value;
    handleChange({ baseObject, name, value });
    // change usermode in local storage
    const localUserData = JSON.parse(localStorage.getItem("user", user));
    localUserData.user_mode = number ? Number(e.target.value) : e.target.value;
    console.log("local userMode:", localUserData);
    localStorage.setItem("user", JSON.stringify(localUserData));
  };

  const handleInput = (e) => {
    console.log("e.target.value:", e.target.value);
    const baseObject = user;
    const name = e.target.name;
    const value = e.target.value;
    handleChange({ baseObject, name, value });
  };

  const updatePassword = () => {
    saveRecord(
      `/auth/update-password/${user?.id}`,
      { newPassword },
      "Password"
    );
    console.log("update attempt completed");
    setNewPassword("");
  };

  const toggleSetPassword = (e) => {
    e.preventDefault();
    if (newPassword.length < 8) {
      displayAlert("Please use at least 8 characters in the password");
      return;
    } else if (newPassword.search(/\d/) < 0) {
      displayAlert("Please include at least one number");
      return;
    } else if (newPassword.search(/[A-Z]/) < 0) {
      displayAlert("Please include at least one capital letter");
      return;
    }
    const confirmed = false;
    toggleConfirmBox({ confirmed });
  };

  const changeUserRole = (e) => {
    const baseObject = user;
    const name = "user.user_roles_id";
    const value = Number(e.target.value);
    handleChange({ baseObject, name, value });
  };

  return (
    <div className="page user-settings">
      {/* ----- Employees ------ */}
      <div className="group one-half">
        <form onSubmit={toggleSetPassword} className="form">
          <h3>Account Settings</h3>
          <div className="form-center">
            {/* Password */}
            <div className="whole flex-parent">
              <div className="two-thirds">
                <Input
                  type="text"
                  name="full_name"
                  labelText="Change Password"
                  value={newPassword || ""}
                  handleChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="one-third flex-parent">
                <button
                  className="btn btn-block align-button"
                  onClick={toggleSetPassword}
                  disabled={isLoading}
                >
                  {isLoading ? "please wait..." : "Change Password"}
                </button>
              </div>
            </div>
            {/* Logout Button */}
            <div className="whole btn-block">
              <button className="btn" type="button" onClick={logoutUser}>
                log out
              </button>
            </div>
            {/* User Role (devloper only) */}
            {JSON.parse(localStorage.getItem("user", user)).user_roles_id <=
            maxUserRolesId ? (
              <div className="whole">
                <Select
                  name="user_roles_id"
                  labelText="Test Different User Roles"
                  value={user?.user_roles_id}
                  handleChange={(e) => changeUserRole(e)}
                  options={lists && lists.listUserRoles}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
      {/* ----- Confirmation Box ------ */}
      <div
        className={
          showConfirmBox ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <ConfirmBox
            confirmedFunction={updatePassword}
            message={`Are you sure you want to change the password for ${user?.full_name}?`}
          />
        </div>
      </div>
      {/* ----- Sandbox Settings ------ */}
      <div className="group one-half">
        <h3>Sandbox Settings</h3>
        <div className="form-center">
          Sandbox mode can be turned on to change the program so it uses an
          alternate data set for testing purposes. Sandbox mode will not send
          emails to customers nor create appointments on the main calendar.
          {/* Appointment Status */}
          <div className="whole">
            <RadioPickButton
              name="user_mode"
              labelText="JMS User Mode"
              handleChange={(e) => handleModeChange(e, true)} // designate isNumber to be true
              value={user_mode || 0}
              options={listUserModes}
              buttonClasses="one-third"
            />
          </div>
          <div>
            When in sandbox mode, admin@freelitetx.com is the default email
            address used for customer emails. Use the box below to temporarily
            change the email address used for testing.
          </div>
          <div className="one-half sub-section">
            <Input
              type="text"
              name="user.customerEmail"
              labelText="Customer Email Address"
              value={customerEmail || ""}
              handleChange={(e) => handleInput(e)}
            />
          </div>
          {/* <div className="one-half sub-section">
            <Input
              type="text"
              name="user.employeeEmail"
              labelText="Employee Email Address"
              value={employeeEmail || ""}
              handleChange={(e) => handleInput(e)}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}
export default UserSettings;
