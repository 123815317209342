import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { Input, Checkbox, Select } from "../../components/dashboard";
import ConfirmBox from "../ConfirmBox";

const AdminUsers = () => {
  const {
    isLoading,
    job,
    createRecord,
    saveRecord,
    employees,
    lists,
    displayAlert,
    handleArrayChanges,
    showConfirmBox,
    toggleConfirmBox,
  } = useAppContext();

  const [selectedRow, setSelectedRow] = useState(null);
  const [index, setIndex] = useState(0);
  const [newPassword, setNewPassword] = useState("");

  const handleInput = (e) => {
    const property = e.target.name;
    const value =
      property === "user_roles_id" ? Number(e.target.value) : e.target.value;
    const baseArray = [...employees];
    const baseName = "employees";
    handleArrayChanges({
      baseName,
      baseArray,
      index,
      newValues: { [property]: value },
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // check employees and create/update
    if (!employees[index]?.id) {
      const record = await createRecord(
        "/auth/register",
        employees[index],
        "option"
      );
      handleArrayChanges({
        baseName: "employees",
        baseArray: [...employees],
        index,
        newValues: { id: record.id },
      });
    } else {
      saveRecord(
        `/auth/update-user/${employees[index].id}`,
        employees[index],
        "employee"
      );
    }

    console.log("update records");
  };
  const selectTblRow = async (e, apptId, row) => {
    setIndex(row);
    setSelectedRow(apptId);
  };
  const handleCheckBox = ({ property, value }) => {
    const baseName = "employees";
    const baseArray = [...employees];
    handleArrayChanges({
      baseName,
      baseArray,
      index,
      newValues: { [property]: value },
    });
  };
  const newEmployee = async (e) => {
    e.preventDefault();
    if (!job.id) {
      displayAlert("Please select a job");
      return;
    }
    handleArrayChanges({
      baseName: "employees",
      baseArray: [...employees],
      index: employees.length || 0,
      newValues: { admin: false, service_rep: false, active: true },
    });
    setIndex(employees.length || 0);
  };

  const updatePassword = () => {
    console.log("hold on to your butts, we're updating this password...");
    saveRecord(
      `/auth/update-password/${employees[index]?.id}`,
      { newPassword },
      "Password"
    );
  };
  const toggleSetPassword = (e) => {
    e.preventDefault();
    if (newPassword.length < 8) {
      displayAlert("Please use at least 8 characters in the password");
      return;
    } else if (newPassword.search(/\d/) < 0) {
      displayAlert("Please include at least one number");
      return;
    } else if (newPassword.search(/[A-Z]/) < 0) {
      displayAlert("Please include at least one capital letter");
      return;
    }
    const confirmed = false;
    toggleConfirmBox({ confirmed });
  };
  const lastAppt = employees[employees?.length - 1];

  return (
    <div className="page">
      {/* ----- Employees ------ */}
      <div className="group whole">
        <form onSubmit={handleSubmit} className="form">
          <h3>All Employees</h3>
          <div className="form-center">
            <div className="form-row whole">
              <div className="employees">
                <table>
                  <tbody>
                    <tr>
                      <th style={{ width: 120 }}>Name</th>
                      <th style={{ width: 120 }}>Title</th>
                      <th style={{ width: 120 }}>Email</th>
                      <th style={{ width: 70 }}>JMS User Role</th>
                    </tr>
                    {employees?.map((employee, index) => {
                      const { full_name, title, email, user_roles_id } =
                        employee;
                      if (user_roles_id > 0) {
                        return (
                          <tr
                            key={employee.id || 0}
                            className={
                              selectedRow === employee.id ? "selected" : ""
                            }
                            onClick={(e) => selectTblRow(e, employee.id, index)}
                          >
                            <td>{full_name}</td>
                            <td>{title}</td>
                            <td>{email}</td>
                            <td>
                              {(user_roles_id &&
                                lists.listUserRoles?.find((role) => {
                                  return role.id === user_roles_id;
                                })?.text) ||
                                "N/A"}
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="btn-block">
              {employees?.length > 0 && !lastAppt?.id ? (
                ""
              ) : (
                <button
                  className="btn"
                  type="button"
                  onClick={(e) => newEmployee(e)}
                  disabled={isLoading}
                >
                  {isLoading ? "please wait..." : "New Employee"}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {/* ----- Details ------ */}
      <div className="group whole">
        <form onSubmit={handleSubmit} className="form">
          <h3>Employee Details</h3>
          <div className="form-center">
            {employees[index] ? (
              <div className="whole flex-parent">
                {/* Left Column */}
                <div className="one-half flex-parent">
                  <div className="whole">
                    <Input
                      type="text"
                      name="full_name"
                      labelText="Full Name"
                      value={employees[index]?.full_name || ""}
                      handleChange={(e) => handleInput(e)}
                    />
                  </div>
                  <div className="whole">
                    <Input
                      type="text"
                      name="title"
                      labelText="title"
                      value={employees[index]?.title || ""}
                      handleChange={(e) => handleInput(e)}
                    />
                  </div>
                  <div className="whole">
                    <Input
                      type="text"
                      name="email"
                      labelText="email"
                      value={employees[index]?.email || ""}
                      handleChange={(e) => handleInput(e)}
                    />
                  </div>
                </div>
                {/* Right Column */}
                <div className="one-half flex-parent">
                  <div className="one-half">
                    <Input
                      type="text"
                      name="nickname"
                      labelText="Nickname"
                      value={employees[index]?.nickname || ""}
                      handleChange={(e) => handleInput(e)}
                    />
                  </div>
                  {/* Color */}
                  <div className="one-half">
                    <Input
                      type="text"
                      name="color"
                      labelText="color"
                      value={employees[index]?.color || ""}
                      handleChange={(e) => handleInput(e)}
                    />
                  </div>
                  {/* User Role */}
                  <div className="whole">
                    <Select
                      name="user_roles_id"
                      labelText="User Role"
                      value={employees[index]?.user_roles_id}
                      handleChange={(e) => handleInput(e)}
                      options={
                        lists &&
                        lists.listUserRoles.filter((role) => role.id > 0)
                      }
                    />
                  </div>
                  {/* is service rep */}
                  <div
                    className={
                      employees[index]?.service_rep
                        ? "one-half checked checkbox outline-box"
                        : "one-half checkbox outline-box"
                    }
                    onClick={(e) =>
                      handleCheckBox({
                        property: "service_rep",
                        value: !employees[index]?.service_rep,
                      })
                    }
                  >
                    <Checkbox
                      type="checkbox"
                      name="service_rep"
                      labelText="Service Rep"
                      checked={employees[index]?.service_rep || false}
                      handleChange={() => {}}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="whole flex-parent">
                <h3>Please select an employee...</h3>
              </div>
            )}
            <div className="button-group whole flex-parent">
              <div className="btn-block">
                <button className="btn" type="submit" disabled={isLoading}>
                  {isLoading ? "please wait..." : "Save Employee"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* ----- Employees ------ */}
      <div className="group whole">
        <form onSubmit={handleSubmit} className="form">
          <h3>Security Settings</h3>
          <div className="form-center">
            {/* Password */}
            <div className="whole flex-parent">
              <div className="three-fourths">
                <Input
                  type="text"
                  name="full_name"
                  labelText="Password"
                  value={newPassword || ""}
                  handleChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="one-fourth flex-parent">
                <button
                  className="btn btn-block"
                  onClick={toggleSetPassword}
                  disabled={isLoading}
                >
                  {isLoading ? "please wait..." : "Change Password"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {/* ----- Confirmation Box ------ */}
      <div
        className={
          showConfirmBox ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <ConfirmBox
            confirmedFunction={updatePassword}
            message={`Are you sure you want to change the password for ${employees[index]?.nickname}?`}
          />
        </div>
      </div>
    </div>
  );
};
export default AdminUsers;
