import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { calcOptionTotal, calcDeposit } from "../../utils/calcOptionTotal";
import {
  TextArea,
  SimpleInputCheckBox,
  DisplayMoney,
  InlineRadioPrice,
} from "../../components/dashboard";

function WorkSpecsMain({ optionsIndex }) {
  const {
    job,
    jobItems,
    options,
    indices,
    discounts,
    fees,
    handleChange,
    handleArrayChange,
  } = useAppContext();

  let index = optionsIndex;

  const handleClick = (e) => {
    // setIndex(e.target.value);
    handleChange({
      baseObject: { ...indices },
      name: "indices.optionsIndex",
      value: e.target.value,
    });
  };

  const handleInput = (e) => {
    const property = e.target.name;
    const value = e.target.value;
    const baseArray = [...options];
    const baseName = "options";
    handleArrayChange({ baseName, baseArray, index, property, value });
  };

  const handleJobInput = (e) => {
    handleChange({
      baseObject: { ...job },
      name: e.target.name,
      value: e.target.value,
    });
  };

  const handleCheck = (e) => {
    const property = "deposit";
    let value = e.target.value;
    const checked = e.target.checked; // grab checked instead of value
    const baseArray = [...options];
    const baseName = "options";
    if (checked) {
      value = 50;
    } else {
      value = 0;
    }
    handleArrayChange({ baseName, baseArray, index, property, value });
  };

  const handleRadio = (e) => {
    const baseName = "options";
    const property = "deposit_modifier";
    const value = Number(e.target.value);
    const baseArray = options;
    handleArrayChange({ baseName, baseArray, index, property, value });
  };

  // ----- Work Specs Main ------
  return (
    <div className="group whole">
      <form className="form">
        <h3>Work Specs</h3>
        <div className="form-center work-specs">
          {/* Work Description */}
          <div className="whole">
            <TextArea
              name="job.work_description"
              labelText="Work Description"
              value={job.work_description || ""}
              handleChange={(e) => handleJobInput(e)}
            />
          </div>
          {/* Deposit */}
          <div className="one-half form-row deposit flex-parent">
            <div className="checkbox">
              {/* combined input and checkbox */}
              <SimpleInputCheckBox
                name="deposit"
                checkName="includeDeposit"
                value={options[index]?.deposit || 0}
                textValue={options[index]?.deposit || 0}
                label=""
                handleCheckboxChange={(e) => handleCheck(e)}
                handleTextChange={(e) => handleInput(e)}
              />
            </div>
            {/* flat or percent radio buttons */}
            <div className="radio checkbox">
              <InlineRadioPrice
                name={`deposit_modifier`}
                value={options[index]?.deposit_modifier}
                handleChange={(e) => {
                  handleRadio(e);
                }}
              />
            </div>
            {/* calculate and display deposit total */}
            <div className="checkbox one-half deposit">
              <DisplayMoney
                name="depositAmount"
                value={
                  calcDeposit({
                    deposit: options[index]?.deposit,
                    deposit_modifier: options[index]?.deposit_modifier,
                    optionTotal: calcOptionTotal({
                      jobItems,
                      discounts,
                      fees,
                    }).grandTotal,
                  }) || "$0.00"
                }
                label="Deposit Amount"
              />
            </div>
          </div>
          <div className="one-half form-row option-total">
            <DisplayMoney
              name="total"
              value={
                calcOptionTotal({ jobItems, discounts, fees }).grandTotal ||
                "$0.00"
              }
              label="Option Total"
            />
          </div>
          {/* <button
                className="btn btn-block"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "please wait..." : "Save"}
              </button> */}
        </div>
      </form>
    </div>
  );
}
export default WorkSpecsMain;
