export const DISPLAY_ALERT = "SHOW_ALERT";
export const CLEAR_ALERT = "CLEAR_ALERT";

export const REGISTER_USER_BEGIN = "REGISTER_USER_BEGIN";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const LOGIN_USER_BEGIN = "LOGIN_USER_BEGIN";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";

export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_CONFIRM_BOX = "TOGGLE_CONFIRM_BOX";
export const TOGGLE_REPORT_FORM = "TOGGLE_REPORT_FORM";
// export const TOGGLE_SHOW_EDIT_ITEM = "TOGGLE_SHOW_EDIT_ITEM"

export const GET_LISTS = "GET_LISTS";
export const GET_DEFAULTS = "GET_DEFAULTS";
export const GET_PRICES = "GET_PRICES";

export const CREATE_JOB_BEGIN = "CREATE_JOB_BEGIN";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_ERROR = "CREATE_JOB_ERROR";

export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const HANDLE_CHANGES = "HANDLE_CHANGES";
export const HANDLE_ARRAY_CHANGE = "HANDLE_ARRAY_CHANGE";
export const HANDLE_ARRAY_CHANGES = "HANDLE_ARRAY_CHANGES";
export const HANDLE_PRIMATIVE_CHANGE = "HANDLE_PRIMATIVE_CHANGE";

export const GET_RECORD_BEGIN = "GET_RECORD_BEGIN";
export const GET_RECORD_SUCCESS = "GET_RECORD_SUCCESS";
export const GET_RECORD_ERROR = "GET_RECORD_ERROR";
export const GET_ARRAY_RECORD_SUCCESS = "GET_ARRAY_RECORD_SUCCESS";

export const SAVE_RECORD_BEGIN = "SAVE_RECORD_BEGIN";
export const SAVE_RECORD_SUCCESS = "SAVE_RECORD_SUCCESS";
export const SAVE_RECORD_ERROR = "SAVE_RECORD_ERROR";

export const DELETE_RECORD_BEGIN = "DELETE_RECORD_BEGIN";
export const DELETE_RECORD_SUCCESS = "DELETE_RECORD_SUCCESS";
export const DELETE_RECORD_ERROR = "DELETE_RECORD_ERROR";

export const CLEAR_CHANGES = "CLEAR_CHANGES";
export const CLEAR_ARRAY_CHANGES = "CLEAR_ARRAY_CHANGES";

export const PROCESS_REPORT_BEGIN = "PROCESS_REPORT_BEGIN";
export const PROCESS_REPORT_SUCCESS = "PROCESS_REPORT_SUCCESS";
export const PROCESS_REPORT_ERROR = "PROCESS_REPORT_ERROR";

export const FILE_UPLOAD_BEGIN = "FILE_UPLOAD_BEGIN";
export const FILE_UPLOAD_ERROR = "FILE_UPLOAD_ERROR";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";
