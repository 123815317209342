import links from "../utils/links";
import { NavLink } from "react-router-dom";

const NavLinks = ({ toggleSidebar, user }) => {
  return (
    <div className="nav-links">
      {links.map((link) => {
        const { text, path, id, icon, maxUserRolesId } = link;
        if (user?.user_roles_id <= maxUserRolesId) {
          return (
            <NavLink
              to={path}
              key={id}
              className={"nav-link"}
              onClick={toggleSidebar}
            >
              <span className="icon">{icon}</span>
              {text}
            </NavLink>
          );
        }
      })}
    </div>
  );
};
export default NavLinks;
