import React from "react"
import { Link } from "react-router-dom"
import Wrapper from "../assets/wrappers/ErrorPage"
import img from "../assets/images/error.svg"

const Error = () => {
  return (
    <Wrapper className="full-page">
      <div>
        <img src={img} alt="error" />
        <h3>Resource not Found</h3>
        <p>Sorry, there was a problem retrieving the page</p>

        <Link to="/">back to dashboard</Link>
      </div>
    </Wrapper>
  )
}

export default Error
