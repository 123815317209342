import { Input, Checkbox } from "../dashboard"
import { useAppContext } from "../../context/appContext"

function NewContact({ index }) {
  const { handleArrayChange, newContacts } = useAppContext()

  const handleInput = (e, baseArray, baseName) => {
    const property = e.target.name
    const value = e.target.value
    // console.log("baseName:", baseName)
    // console.log("baseArray:", baseArray)
    // console.log("index:", index)
    // console.log("property:", property)
    // console.log("value:", value)
    handleArrayChange({ baseName, baseArray, index, property, value })
  }
  const handleCheckBox = (e, baseArray, baseName) => {
    const property = e.target.name
    const value = e.target.checked
    handleArrayChange({ baseName, baseArray, index, property, value })
  }

  return (
    <div className="form-center sub-item">
      <div className="two-thirds">
        <Input
          type="text"
          name="contact_name"
          labelText="Contact Name"
          value={newContacts[index].contact_name || ""}
          handleChange={(e) => handleInput(e, [...newContacts], "newContacts")}
        />
      </div>
      <div className="one-third">
        <Input
          type="text"
          name="phone"
          labelText="Phone"
          value={newContacts[index].phone || ""}
          handleChange={(e) => handleInput(e, [...newContacts], "newContacts")}
        />
      </div>
      <div className="one-fourth checkbox">
        <Checkbox
          type="checkbox"
          name="is_active"
          labelText="Active"
          checked={newContacts[index].is_active || false}
          handleChange={(e) => handleCheckBox(e, [...newContacts], "newContacts")}
        />
      </div>
      <div className="three-fourths">
        <Input
          type="text"
          name="email"
          value={newContacts[index].email || ""}
          handleChange={(e) => handleInput(e, [...newContacts], "newContacts")}
        />
      </div>
      <div className="whole">
        <Input
          type="text"
          name="notes"
          value={newContacts[index].notes || ""}
          handleChange={(e) => handleInput(e, [...newContacts], "newContacts")}
        />
      </div>
    </div>
  )
}
export default NewContact
