import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import {
  Input,
  TextArea,
  Select,
  RadioPickButton,
} from "../../components/dashboard";
import EmailReportForm from "../../components/EmailReportForm";

function Appointments() {
  const {
    appointments,
    job,
    lists,
    indices,
    displayAlert,
    isLoading,
    handleChange,
    handleArrayChanges,
    showReportForm,
  } = useAppContext();
  const index = indices.appointmentsIndex;

  const [selectedRow, setSelectedRow] = useState(null);

  const handleInput = (e, isNumber) => {
    const property = e.target.name;
    const value = isNumber ? parseInt(e.target.value) : e.target.value;
    const baseArray = [...appointments];
    const baseName = "appointments";
    handleArrayChanges({
      baseName,
      baseArray,
      index,
      newValues: { [property]: value },
    });
  };

  const selectTblRow = async (apptId, row) => {
    console.log("change appointment index to:", row);
    // set appointmentsIndex
    handleChange({
      baseObject: { ...indices },
      name: "indices.appointmentsIndex",
      value: row,
    });
    setSelectedRow(apptId);
  };
  const newAppt = async (e) => {
    e.preventDefault();
    if (!job.id) {
      displayAlert("Please select a job");
      return;
    }
    const today = new Date().toISOString().slice(0, 10);
    handleArrayChanges({
      baseName: "appointments",
      baseArray: [...appointments],
      index: appointments.length || 0,
      newValues: {
        core_job_data_id: job.id,
        appt_date: today,
        appt_statuses_id: 1,
      },
    });
    // set appointmentsIndex
    handleChange({
      baseObject: { ...indices },
      name: "indices.appointmentsIndex",
      value: appointments.length || 0,
    });
  };
  const lastAppt = appointments[appointments?.length - 1];

  return (
    <div className="page appointments">
      {/* ----- Appointments ------ */}
      <div className="group whole">
        <form className="form">
          <h3>Appointments</h3>
          <div className="form-center">
            <div className="form-row whole">
              <table>
                <tbody>
                  <tr>
                    <th style={{ width: 150 }}>Type</th>
                    <th style={{ width: 150 }}>Date</th>
                    <th style={{ width: 150 }}>Service Rep</th>
                    <th className="mobile-no-show">Notes</th>
                  </tr>
                  {appointments?.map((appt, index) => {
                    return (
                      <tr
                        key={appt.id || 0}
                        className={selectedRow === appt.id ? "selected" : ""}
                        onClick={() => selectTblRow(appt.id, index)}
                      >
                        <td>
                          {appt.appt_types_id &&
                            lists.listApptTypes?.find((appointment) => {
                              return appointment.id === appt.appt_types_id;
                            })?.text}
                        </td>
                        <td>{appt.appt_date}</td>
                        <td>
                          {appt.employees_id &&
                            lists.listAllServiceReps?.find((employee) => {
                              return employee.id === appt.employees_id;
                            })?.text}
                        </td>
                        <td className="mobile-no-show">{appt.notes}</td>
                      </tr>
                    );
                  })}
                  {/* <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td className="mobile-no-show">&nbsp;</td>
                  </tr> */}
                </tbody>
              </table>
            </div>
            <div className="btn-block">
              {appointments?.length > 0 && !lastAppt?.id ? (
                ""
              ) : (
                <button
                  name="newAppt"
                  className="btn"
                  type="button"
                  onClick={(e) => newAppt(e)}
                  disabled={isLoading}
                >
                  {isLoading ? "please wait..." : "New Appointment"}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {/* ----- Details ------ */}
      <div className="group whole">
        <form className="form">
          <h3>Details</h3>
          <div className="form-center">
            {appointments[index]?.core_job_data_id ? (
              <div className="whole flex-parent">
                <div className="one-half flex-parent sub-section">
                  {/* Appointment Status */}
                  <div className="whole">
                    <RadioPickButton
                      name="appt_statuses_id"
                      labelText="Appoinment Status"
                      handleChange={(e) => handleInput(e, true)} // designate isNumber to be true
                      value={appointments[index]?.appt_statuses_id || "default"}
                      options={lists && lists.listApptStatuses}
                      buttonClasses="one-third"
                    />
                  </div>
                  <div className="one-half sub-section">
                    <Select
                      name="appt_types_id"
                      labelText="Appt Type"
                      handleChange={(e) => handleInput(e, true)} // designate isNumber to be true
                      value={appointments[index]?.appt_types_id || "default"}
                      options={lists && lists.listApptTypes}
                    />
                  </div>
                  <div className="one-half">
                    <Select
                      name="employees_id"
                      labelText="Service Rep"
                      handleChange={(e) => handleInput(e, true)} // designate isNumber to be true
                      value={appointments[index]?.employees_id || "default"}
                      options={lists && lists.listServiceReps}
                    />
                  </div>

                  <div className="one-third sub-section">
                    <Input
                      type="date"
                      name="appt_date"
                      labelText="date"
                      value={appointments[index]?.appt_date || ""}
                      handleChange={(e) => handleInput(e)}
                    />
                  </div>
                  <div className="one-third sub-section">
                    <Input
                      type="time"
                      name="appt_time"
                      labelText="time"
                      value={appointments[index]?.appt_time || ""}
                      handleChange={(e) => handleInput(e)}
                    />
                  </div>
                  <div className="one-third">
                    <Input
                      type="text"
                      name="duration"
                      labelText="Duration (hrs)"
                      value={appointments[index]?.duration || ""}
                      handleChange={(e) => handleInput(e)}
                    />
                  </div>
                </div>
                <div className="one-half">
                  <TextArea
                    name="notes"
                    value={appointments[index]?.notes || ""}
                    handleChange={(e) => handleInput(e)}
                  />
                </div>
              </div>
            ) : (
              <div className="whole flex-parent">
                <h3>Please select an appointment...</h3>
              </div>
            )}
          </div>
        </form>
      </div>
      {/* ----- Email/ Report Confirmation Modal ------ */}
      <div
        className={
          showReportForm ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <EmailReportForm />
        </div>
      </div>
    </div>
  );
}
export default Appointments;
