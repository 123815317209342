import { FaList, FaFolderPlus, FaUserCircle } from "react-icons/fa";
import {
  BsClipboardData,
  BsCalendar4Week,
  BsGear,
  BsCashCoin,
  BsRulers,
} from "react-icons/bs";

const links = [
  {
    id: 1,
    text: "All Jobs",
    path: "all-jobs",
    icon: <FaList />,
    maxUserRolesId: 10,
  },
  {
    id: 2,
    text: "Add Job",
    path: "add-job",
    icon: <FaFolderPlus />,
    maxUserRolesId: 2,
  },
  {
    id: 3,
    text: "Job Summary",
    path: "job-summary",
    icon: <BsClipboardData />,
    maxUserRolesId: 10,
  },
  {
    id: 4,
    text: "Work Specs",
    path: "work-specs",
    icon: <BsRulers />,
    maxUserRolesId: 10,
  },
  {
    id: 5,
    text: "Appointments",
    path: "appointments",
    icon: <BsCalendar4Week />,
    maxUserRolesId: 2,
  },
  {
    id: 6,
    text: "Payments",
    path: "payments",
    icon: <BsCashCoin />,
    maxUserRolesId: 2,
  },
  { id: 7, text: "Admin", path: "admin", icon: <BsGear />, maxUserRolesId: 1 },
  {
    id: 8,
    text: "User Settings",
    path: "user-settings",
    icon: <FaUserCircle />,
    maxUserRolesId: 10,
  },
];

export default links;
