import { Outlet } from "react-router-dom";
import Wrapper from "../../assets/wrappers/SharedLayout.js";
import {
  Alert,
  Loading,
  Navbar,
  SmallSidebar,
  BigSidebar,
} from "../../components/index.js";
import { useAppContext } from "../../context/appContext.js";

const SharedLayout = () => {
  const { showAlert, serverProcessing, user } = useAppContext();
  return (
    <Wrapper>
      <main className="dashboard">
        <SmallSidebar />
        <BigSidebar />
        <div className={user?.user_mode ? "sandbox" : ""}>
          <Navbar />
          <div className={user?.user_mode ? "dashboard-page" : ""}>
            {showAlert && <Alert />}
            {serverProcessing && <Loading />}
            <Outlet />
          </div>
        </div>
      </main>
    </Wrapper>
  );
};

export default SharedLayout;
