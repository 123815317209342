import { moneyToNumber } from "../utils/money";

const calcOptionTotal = ({ jobItems, discounts, fees }) => {
  // calculate job items total
  const jobsTotal = jobItems?.reduce((optionTtl, item) => {
    let { base_price, custom_price, extras_price, quantity } = item;
    // make sure given values are numbers
    base_price = moneyToNumber(base_price);
    custom_price = moneyToNumber(custom_price);
    extras_price = moneyToNumber(extras_price);

    const ttl = custom_price ? custom_price : base_price + extras_price;
    return optionTtl + ttl * quantity;
  }, 0);

  // add price adjustments
  let priceAdjs = [];
  let grandTotal = jobsTotal;
  // console.log("grandTotal1:", grandTotal);
  grandTotal = discounts?.reduce((optionTtl, discount) => {
    let { usePA, amount, modifier, description } = discount;
    amount = Number(amount);
    let priceAdj = 0;
    if (usePA) {
      // modifier 1 is % and 2 is flat amount
      priceAdj = modifier === 1 ? (amount / 100) * jobsTotal : amount;
    }
    priceAdjs.push({ description, priceAdj });
    return optionTtl + priceAdj;
  }, grandTotal);
  grandTotal = fees?.reduce((optionTtl, fee) => {
    let { usePA, amount, modifier } = fee;
    amount = Number(amount);
    let priceAdj = 0;
    if (usePA) {
      // modifier 1 is % and 2 is flat amount
      priceAdj = modifier === 1 ? (amount / 100) * jobsTotal : amount;
    }
    return optionTtl + priceAdj;
  }, grandTotal);
  // console.log("grandTotal2:", grandTotal);
  return { jobsTotal, priceAdjs, grandTotal };
};

const calcDeposit = ({ deposit, deposit_modifier, optionTotal }) => {
  // console.log("deposit:", deposit);
  if (parseInt(deposit_modifier) === 2) {
    return Number(deposit);
  }
  return (Number(deposit) * optionTotal) / 100;
};

export { calcOptionTotal, calcDeposit };
