import Wrapper from "../assets/wrappers/SmallSidebar";
import { FaTimes } from "react-icons/fa";
import { useAppContext } from "../context/appContext";
import NavLinks from "./NavLinks";
import { Logo } from ".";

function SmallSidebar() {
  const { showSidebar, toggleSidebar, user } = useAppContext();

  // add show-sidebar class to display the sidebar
  let mainClassName = !showSidebar
    ? "show-sidebar sidebar-container"
    : "sidebar-container";
  // add sandbox class if selected
  mainClassName = user?.user_mode ? mainClassName + " sandbox" : mainClassName;

  return (
    <Wrapper>
      <div
        className={
          showSidebar ? "show-sidebar sidebar-container" : "sidebar-container"
        }
      >
        <div className={mainClassName}>
          <button className="close-btn" type="button" onClick={toggleSidebar}>
            <FaTimes />
          </button>
          <div className="logo-area">
            <Logo />
          </div>
          <NavLinks toggleSidebar={toggleSidebar} user={user} />
        </div>
      </div>
    </Wrapper>
  );
}
export default SmallSidebar;
