import { useAppContext } from "../context/appContext";
import { Navigate } from "react-router-dom";

function ProtectedRouteUser({ children }) {
  const { user } = useAppContext();
  if (user?.user_roles_id <= 4) {
    return children;
  }
  return <Navigate to="/login" />;
}
function ProtectedRouteManager({ children }) {
  const { user } = useAppContext();
  if (user?.user_roles_id <= 2) {
    return children;
  }
  return <Navigate to="/login" />;
}
function ProtectedRouteAdmin({ children }) {
  const { user } = useAppContext();
  if (user?.user_roles_id <= 1) {
    return children;
  }
  return <Navigate to="/login" />;
}

export { ProtectedRouteUser, ProtectedRouteManager, ProtectedRouteAdmin };
