import { useState } from "react";
import { useAppContext } from "../../context/appContext";
import { Input, Select, DisplayMoney } from "../../components/dashboard";
import EmailReportForm from "../../components/EmailReportForm";
import { calcOptionTotal, calcDeposit } from "../../utils/calcOptionTotal";
import { moneyToNumber } from "../../utils/money";

function Payments() {
  const {
    payments,
    lists,
    job,
    jobItems,
    fees,
    discounts,
    options,
    indices,
    displayAlert,
    isLoading,
    handleChange,
    handleArrayChanges,
    showReportForm,
  } = useAppContext();
  const index = indices.paymentsIndex;

  const [selectedRow, setSelectedRow] = useState(null);

  const handleInput = (e, isNumber) => {
    const property = e.target.name;
    const value = isNumber ? parseInt(e.target.value) : e.target.value;
    const baseArray = [...payments];
    const baseName = "payments";
    handleArrayChanges({
      baseName,
      baseArray,
      index,
      newValues: { [property]: value },
    });
  };
  const selectTblRow = async (apptId, row) => {
    // set paymentsIndex
    handleChange({
      baseObject: { ...indices },
      name: "indices.paymentsIndex",
      value: row,
    });
    setSelectedRow(apptId);
  };
  const newPayment = async (e) => {
    e.preventDefault();
    if (!job.id) {
      displayAlert("Please select a job");
      return;
    }
    const today = new Date().toISOString().slice(0, 10);
    handleArrayChanges({
      baseName: "payments",
      baseArray: [...payments],
      index: payments.length || 0,
      newValues: { core_job_data_id: job.id, payment_date: today },
    });
    // set paymentsIndex
    handleChange({
      baseObject: { ...indices },
      name: "indices.paymentsIndex",
      value: payments.length || 0,
    });
  };

  const totals = calcOptionTotal({
    jobItems,
    discounts,
    fees,
  });
  const { grandTotal } = totals; // jobsTotal, priceAdjs,

  const getDeposit = () => {
    // find selected option or use first as default
    let index = options.findIndex((option) => {
      // console.log("looking for the right option...");
      return option.id === job.options_id;
    }); // if not found returns -1
    if (index < 0) {
      index = 0; // change to first option if no match found
    }
    const deposit = options[index]?.deposit;
    const deposit_modifier = options[index]?.deposit_modifier;
    const optionTotal = grandTotal;
    return calcDeposit({ deposit, deposit_modifier, optionTotal });
  };
  const getPaid = () => {
    return payments.reduce((ttl, payment) => {
      return ttl + moneyToNumber(payment.amount);
    }, 0);
  };
  const getDue = () => {
    return grandTotal - getPaid();
  };
  const lastAppt = payments[payments?.length - 1];

  return (
    <div className="page">
      {/* ----- Payments ------ */}
      <div className="group whole">
        <form className="form">
          <h3>Payments</h3>
          <div className="form-center">
            <div className="form-row whole">
              <table>
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Method</th>
                    <th>Amount</th>
                  </tr>
                  {payments?.map((payment, index) => {
                    return (
                      <tr
                        key={payment?.id || index}
                        className={selectedRow === payment.id ? "selected" : ""}
                        onClick={(e) => selectTblRow(payment.id, index)}
                      >
                        <td>{payment.payment_date}</td>
                        <td>
                          {payment.payment_types_id &&
                            lists.listPaymentTypes?.find((payTypes) => {
                              return payTypes.id === payment.payment_types_id;
                            }).text}
                        </td>
                        <td>
                          {payment.payment_methods_id &&
                            lists.listPaymentMethods?.find((payMethods) => {
                              return (
                                payMethods.id === payment.payment_methods_id
                              );
                            }).text}
                        </td>
                        <td>{payment.amount}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="btn-block">
              {payments?.length > 0 && !lastAppt?.id ? (
                ""
              ) : (
                <button
                  name="newPayment"
                  className="btn"
                  type="button"
                  onClick={(e) => newPayment(e)}
                  disabled={isLoading}
                >
                  {isLoading ? "please wait..." : "New Payment"}
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
      {/* ----- Balance ------ */}
      <div className="group one-half">
        <form className="form">
          <h3>Current Balance</h3>
          <div className="form-center">
            <div className="whole">
              <DisplayMoney name="Grand Total" value={grandTotal} />
              <DisplayMoney name="Required Depost" value={getDeposit()} />
              <DisplayMoney name="Amount Paid" value={getPaid()} />
              <DisplayMoney name="Remaining Balance" value={getDue()} />
            </div>
          </div>
        </form>
      </div>
      {/* ----- Details ------ */}
      <div className="group one-half">
        <form className="form">
          <h3>Details</h3>
          <div className="form-center">
            {payments[index]?.core_job_data_id ? (
              <div className="whole flex-parent">
                <div className="whole">
                  <Select
                    name="payment_types_id"
                    labelText="Payment Type"
                    handleChange={(e) => handleInput(e, true)} // designate isNumber as true
                    value={payments[index]?.payment_types_id || "default"}
                    options={lists && lists.listPaymentTypes}
                  />
                </div>
                <div className="whole">
                  <Input
                    type="text"
                    name="amount"
                    value={payments[index]?.amount || ""}
                    handleChange={(e) => handleInput(e)}
                  />
                </div>
                <div className="whole">
                  <Select
                    name="payment_methods_id"
                    labelText="Payment Method"
                    handleChange={(e) => handleInput(e, true)} // designate isNumber as true
                    value={payments[index]?.payment_methods_id || "default"}
                    options={lists && lists.listPaymentMethods}
                  />
                </div>
                <div className="one-half sub-section">
                  <Input
                    type="text"
                    name="digits"
                    value={payments[index]?.digits || ""}
                    handleChange={(e) => handleInput(e)}
                  />
                </div>
                <div className="one-half">
                  <Input
                    type="date"
                    name="payment_date"
                    labelText="Date"
                    value={payments[index]?.payment_date || ""}
                    handleChange={(e) => handleInput(e)}
                  />
                </div>

                {/* <div className="whole">
                    <button
                      className="btn btn-block"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "please wait..." : "Save"}
                    </button>
                  </div> */}
              </div>
            ) : (
              <div className="whole flex-parent">
                <h3>Please select a payment...</h3>
              </div>
            )}
          </div>
        </form>
      </div>
      {/* ----- Email/ Report Confirmation Modal ------ */}
      <div
        className={
          showReportForm ? "show-modal modal-container" : "modal-container"
        }
      >
        <div className="group">
          <EmailReportForm />
        </div>
      </div>
    </div>
  );
}
export default Payments;
