import { Display, Display1, DisplayMoney } from "../dashboard";
import { useAppContext } from "../../context/appContext";
import { moneyToNumber } from "../../utils/money";

function ReportsJobItems({ jbItms }) {
  const { lists } = useAppContext();
  // console.log("jbItms[editItem.index]:", jbItms[editItem.index])
  return (
    <div className="whole">
      <h3>Job Items</h3>
      <div className="subform">
        {jbItms?.map((item, index) => {
          const {
            work_types_id,
            quantity,
            skylight_sizes_id,
            top_glazing_id,
            bottom_glazing_id,
            frame_types_id,
            tube_depths_id,
            glazing_materials_id,
            work_description,
            extras_ids,
            base_price,
            extras_price,
            custom_price,
          } = item;
          // convert money string to a number
          const basePrice = moneyToNumber(base_price);
          const extrasPrice = moneyToNumber(extras_price);
          const customPrice = moneyToNumber(custom_price);
          // build values
          const jobType = // if value present (including 0)
            (work_types_id || work_types_id === 0) &&
            lists.listWorkTypes?.find((payType) => {
              return payType.id === work_types_id;
            }).text;
          const size =
            (skylight_sizes_id || skylight_sizes_id === 0) &&
            lists.listSkylightSizes?.find((size) => {
              return size.id === skylight_sizes_id;
            }).text;
          const glazingMaterial =
            (glazing_materials_id || glazing_materials_id === 0) &&
            lists.listGlazingMaterials?.find((glazing) => {
              return glazing.id === glazing_materials_id;
            }).text;
          const topGlazing =
            (top_glazing_id || top_glazing_id === 0) &&
            lists.listGlazingColors?.find((glazing) => {
              return glazing.id === top_glazing_id;
            }).text;
          const bottomGlazing =
            (bottom_glazing_id || bottom_glazing_id === 0) &&
            lists.listGlazingColors?.find((glazing) => {
              return glazing.id === bottom_glazing_id;
            }).text;
          const color = bottomGlazing
            ? topGlazing + "/" + bottomGlazing
            : topGlazing;
          const frame =
            (frame_types_id || frame_types_id === 0) &&
            lists.listFrameTypes?.find((frame) => {
              return frame.id === frame_types_id;
            }).text;
          const depth =
            (tube_depths_id || tube_depths_id === 0) &&
            lists.listTubeDepths?.find((depth) => {
              return depth.id === tube_depths_id;
            }).text;
          const extras = extras_ids
            ?.map((chosenExtra) => {
              return lists.listExtras?.find((listedExtra) => {
                return listedExtra.id === chosenExtra;
              })?.text;
            })
            .toString();
          let unitPrice = customPrice // if custom price
            ? customPrice // use custom price
            : basePrice + extrasPrice; // else calculate price

          const ttlPrice = unitPrice * quantity;
          return (
            <div className="form-center sub-item" key={index}>
              {/* First Column */}
              <div className="one-third">
                <Display name="Job Type" value={jobType} />
                <Display name="Approximate Size" value={size || "N/A"} />
                <Display name="Frame" value={frame || "N/A"} />
                {work_types_id !== 5 ? (
                  // for all standard skylights
                  <>
                    <Display name="Glazing" value={glazingMaterial || "N/A"} />
                    <Display name="Color" value={color || "N/A"} />
                  </>
                ) : (
                  // for sun tunnels
                  <>
                    <Display name="tube depth" value={depth || "N/A"} />
                    <Display name="light kit" value={extras || "N/A"} />
                  </>
                )}
              </div>
              {/* Second Column */}
              <div className="one-third">
                <DisplayMoney
                  name="Base Price"
                  value={customPrice || basePrice}
                />
                <DisplayMoney name="Line Items" value={extrasPrice} />
                <DisplayMoney name="Unit Price" value={unitPrice} />
                <Display name="Quantity" value={quantity} />
                <div className="bold">
                  <DisplayMoney name="Total" value={ttlPrice} />
                </div>
              </div>
              {/* Third Column */}
              <div className="one-third details">
                <Display1
                  name="Details"
                  value={work_description || ""}
                  handleChange={() => {}}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default ReportsJobItems;
