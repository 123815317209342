const InlineRadioPrice = ({ name, value, handleChange }) => {
  // console.log("radio:", value)
  return (
    <div className="form-row">
      <label htmlFor="dollar" className="form-label">
        $
      </label>
      <input
        type="radio"
        value={2}
        name={name}
        onChange={handleChange}
        className="form-input"
        checked={value === 2 ? true : false}
      />
      <label htmlFor="percent" className="form-label">
        %
      </label>
      <input
        type="radio"
        value={Number(1)}
        name={name}
        onChange={handleChange}
        className="form-input"
        checked={value === 1 ? true : false}
      />
    </div>
  );
};
const InlineRadioMeasure = ({ name, value, handleChange }) => {
  // console.log("radio:", value)
  return (
    <div className="form-row whole">
      <label htmlFor="OSF" className="form-label">
        OSF
      </label>
      <input
        type="radio"
        value="OSF"
        name={name}
        onChange={handleChange}
        className="form-input"
        checked={value === "OSF" ? true : false}
      />
      <label htmlFor="Exact" className="form-label">
        Exact Measure
      </label>
      <input
        type="radio"
        value="Exact"
        name={name}
        onChange={handleChange}
        className="form-input"
        checked={value === "Exact" ? true : false}
      />
      <div>
        <label htmlFor="Rough" className="form-label">
          Rough Open
        </label>
        &nbsp;
        <input
          type="radio"
          value="Rough"
          name={name}
          onChange={handleChange}
          className="form-input"
          checked={value === "Rough" ? true : false}
        />
        <label htmlFor="OCM" className="form-label">
          OCM
        </label>
        <input
          type="radio"
          value="OCM"
          name={name}
          onChange={handleChange}
          className="form-input"
          checked={value === "OCM" ? true : false}
        />
      </div>
    </div>
  );
};

const BasicInlineRadio = ({ optionNames, labels, value, handleChange }) => {
  // console.log("radio:", value)
  return (
    <div className="form-row">
      {optionNames?.map((optionName, index) => {
        return (
          <div key={index} className="whole">
            <label htmlFor="dollar" className="form-label">
              {labels[index]}
            </label>
            <input
              type="radio"
              value={index}
              name={optionName}
              onChange={handleChange}
              className="form-input"
              checked={value === index ? true : false}
            />
          </div>
        );
      })}
    </div>
  );
};
export { InlineRadioPrice, InlineRadioMeasure, BasicInlineRadio };
