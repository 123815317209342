import { Input, Select, RadioPickButton } from "../../dashboard";
import { useAppContext } from "../../../context/appContext";
import calcJobItemTotal from "../../../utils/calcJobItemTotal";

const Size = ({ editingItem, setEditItem }) => {
  const { job, customer, prices, lists } = useAppContext();
  const {
    work_types_id,
    skylight_sizes_id,
    exact_width,
    exact_length,
    tube_depths_id,
  } = editingItem;

  const tubeDiameters = [
    // a partial list of skylight sizes
    { id: 12, text: '10"' },
    { id: 13, text: '14"' },
  ];
  const handleInput = (e) => {
    // add the extra step of a pricing update for relevant fields
    console.log("e.target.name:", e.target.name);
    console.log("e.target.value:", e.target.value);
    const newEditItem = {
      ...editingItem,
      [e.target.name]: parseInt(e.target.value),
    };
    const newPrices = calcJobItemTotal({
      newEditItem,
      job,
      customer,
      prices,
      lists,
    });
    setEditItem({ ...newEditItem, ...newPrices });
  };

  return (
    <div className="one-third">
      {/* ------ For Work Types but Sun Tunnel and Labor ------ */}
      {work_types_id !== 5 && work_types_id !== 13 && (
        <>
          {/* Skylight Size */}
          <div className="whole">
            <Select
              type="text"
              name="skylight_sizes_id"
              labelText="Skylight Size"
              value={skylight_sizes_id}
              handleChange={(e) => handleInput(e)}
              options={
                lists &&
                lists.listSkylightSizes?.filter(
                  (size) => size.skylight_type === "standard"
                )
              }
            />
          </div>
          {/* Exact Size */}
          <div className="exact-size flex-parent">
            <div className="one-half sub-section">
              <Input
                type="text"
                name="exact_width"
                labelText="Exact Width"
                value={exact_width || ""}
                handleChange={(e) =>
                  setEditItem({
                    ...editingItem,
                    exact_width: e.target.value,
                  })
                }
              />
            </div>
            <div className="one-half">
              <Input
                type="text"
                name="exact_length"
                labelText="Exact Length"
                value={exact_length || ""}
                handleChange={(e) =>
                  setEditItem({
                    ...editingItem,
                    exact_length: e.target.value,
                  })
                }
              />
            </div>
          </div>
          {/* Measurement Type */}
          <div className="whole measurement-types">
            <RadioPickButton
              name="measurement_types_id"
              labelText="Measurement Type"
              handleChange={(e) =>
                setEditItem({
                  ...editingItem,
                  measurement_types_id: e.target.value,
                })
              }
              value={editingItem?.measurement_types_id || "default"}
              options={lists && lists.listMeasurementTypes}
              buttonClasses="one-fourth"
            />
          </div>
        </>
      )}

      {/* ------ Sun Tunnel Only ------ */}
      {work_types_id === 5 && (
        <>
          {/* Tube Depth */}
          <div className="whole">
            <Select
              type="text"
              name="tube_depths_id"
              labelText="Tube Depth"
              value={tube_depths_id}
              handleChange={(e) => handleInput(e)}
              options={lists && lists.listTubeDepths}
            />
          </div>
          {/* Tube Diameter */}
          <div className="whole">
            <RadioPickButton
              name="skylight_sizes_id"
              labelText="Tube Diameter"
              handleChange={(e) => handleInput(e)}
              value={editingItem?.skylight_sizes_id || "default"}
              options={tubeDiameters}
              buttonClasses="one-half"
            />
          </div>
        </>
      )}
    </div>
  );
};
export default Size;
