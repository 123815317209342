import {
  DISPLAY_ALERT,
  CLEAR_ALERT,
  LOGIN_USER_BEGIN,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  TOGGLE_CONFIRM_BOX,
  TOGGLE_REPORT_FORM,
  TOGGLE_SIDEBAR,
  LOGOUT_USER,
  GET_LISTS,
  GET_DEFAULTS,
  GET_PRICES,
  CREATE_JOB_BEGIN,
  CREATE_JOB_ERROR,
  CREATE_JOB_SUCCESS,
  HANDLE_CHANGE,
  HANDLE_CHANGES,
  HANDLE_ARRAY_CHANGE,
  HANDLE_ARRAY_CHANGES,
  HANDLE_PRIMATIVE_CHANGE,
  SAVE_RECORD_BEGIN,
  SAVE_RECORD_SUCCESS,
  SAVE_RECORD_ERROR,
  CLEAR_CHANGES,
  CLEAR_ARRAY_CHANGES,
  GET_RECORD_BEGIN,
  GET_RECORD_SUCCESS,
  GET_ARRAY_RECORD_SUCCESS,
  GET_RECORD_ERROR,
  DELETE_RECORD_BEGIN,
  DELETE_RECORD_SUCCESS,
  DELETE_RECORD_ERROR,
  PROCESS_REPORT_BEGIN,
  PROCESS_REPORT_ERROR,
  PROCESS_REPORT_SUCCESS,
  FILE_UPLOAD_BEGIN,
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_SUCCESS,
} from "./actions";
import { initialState } from "./appContext";

const reducer = (state, action) => {
  //  ----- Alerts -----
  if (action.type === DISPLAY_ALERT) {
    return {
      ...state,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.message,
    };
  }
  if (action.type === CLEAR_ALERT) {
    return {
      ...state,
      showAlert: false,
      alertType: "",
      alertText: "",
    };
  }
  //  ----- Login User -----
  if (action.type === LOGIN_USER_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === LOGIN_USER_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      token: action.payload.token,
      user: action.payload.user,
      showAlert: true,
      alertType: "success",
      alertText: "Login successful",
    };
  }
  if (action.type === LOGIN_USER_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === TOGGLE_CONFIRM_BOX) {
    return {
      ...state,
      showConfirmBox: !state.showConfirmBox,
    };
  }
  //  ----- Logout User -----
  if (action.type === LOGOUT_USER) {
    return {
      ...initialState,
      user: null,
      token: null,
      showAlert: true,
      alertType: "danger",
      alertText: "Session Expired",
    };
  }
  //  ----- Nav Bar -----
  if (action.type === TOGGLE_SIDEBAR) {
    return {
      ...state,
      showSidebar: !state.showSidebar,
    };
  }
  if (action.type === GET_LISTS) {
    return {
      ...state,
      lists: action.payload,
    };
  }
  if (action.type === GET_DEFAULTS) {
    return {
      ...state,
      defaults: action.payload,
    };
  }
  if (action.type === GET_PRICES) {
    return {
      ...state,
      prices: action.payload,
    };
  }
  // ----- Global State Handlers -----
  if (action.type === HANDLE_CHANGE) {
    const { baseObject, baseName, name, value } = action.payload;
    return {
      ...state,
      [baseName]: {
        ...baseObject,
        [name]: value,
      },
    };
  }
  if (action.type === HANDLE_CHANGES) {
    const { baseName, baseObject, newValues } = action.payload;
    return {
      ...state,
      [baseName]: {
        ...baseObject,
        ...newValues,
      },
    };
  }
  if (action.type === HANDLE_ARRAY_CHANGE) {
    const { baseArray, baseName, index, property, value } = action.payload;
    return {
      ...state,
      [baseName]: [
        ...baseArray.slice(0, index),
        {
          ...baseArray[index],
          [property]: value,
        },
        ...baseArray.slice(index + 1),
      ],
    };
  }
  if (action.type === HANDLE_ARRAY_CHANGES) {
    const { baseArray, baseName, index, newValues } = action.payload;
    return {
      ...state,
      [baseName]: [
        ...baseArray.slice(0, index),
        {
          ...baseArray[index],
          ...newValues,
        },
        ...baseArray.slice(index + 1),
      ],
    };
  }
  if (action.type === HANDLE_PRIMATIVE_CHANGE) {
    const { property, value } = action.payload;
    return {
      ...state,
      [property]: value,
    };
  }
  // ----- Global Save & Clear Changes Buttons -----
  if (action.type === CLEAR_CHANGES) {
    return {
      ...state,
      [action.payload.baseName]: { ...action.payload.defaultState },
    };
  }
  if (action.type === CLEAR_ARRAY_CHANGES) {
    return {
      ...state,
      [action.payload.baseName]: [...action.payload.defaultState],
    };
  }
  if (action.type === SAVE_RECORD_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === SAVE_RECORD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.msg,
    };
  }
  if (action.type === SAVE_RECORD_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  // ----- Global get record -----
  if (action.type === GET_RECORD_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === GET_RECORD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      [action.payload.baseName]: {
        ...action.payload.baseObject,
      },
    };
  }
  if (action.type === GET_ARRAY_RECORD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      [action.payload.baseName]: [...action.payload.baseObject],
    };
  }
  if (action.type === GET_RECORD_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  // ----- Global Delete Record -----
  if (action.type === DELETE_RECORD_BEGIN) {
    return { ...state, isLoading: true };
  }
  if (action.type === DELETE_RECORD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: action.payload.msg,
    };
  }
  if (action.type === DELETE_RECORD_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  // ----- Core Job -----
  if (action.type === CREATE_JOB_BEGIN) {
    return { ...state, isLoading: true, serverProcessing: true };
  }
  if (action.type === CREATE_JOB_SUCCESS) {
    return {
      ...state,
      newJob: {},
      newAddress: {},
      newCustomer: {},
      newContacts: [],
      isLoading: false,
      serverProcessing: false,
      showAlert: true,
      alertType: "success",
      alertText: "New Job Created",
    };
  }
  if (action.type === CREATE_JOB_ERROR) {
    return {
      ...state,
      isLoading: false,
      serverProcessing: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  // ----- Create Reports -----
  if (action.type === PROCESS_REPORT_BEGIN) {
    return { ...state, isLoading: true, serverProcessing: true };
  }
  if (action.type === PROCESS_REPORT_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      serverProcessing: false,
      showAlert: true,
      alertType: "success",
      alertText: "New Report Processed",
      report: { ...initialState.report },
      calendar: { ...initialState.calendar },
    };
  }
  if (action.type === PROCESS_REPORT_ERROR) {
    return {
      ...state,
      isLoading: false,
      serverProcessing: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
    };
  }
  if (action.type === TOGGLE_REPORT_FORM) {
    return {
      ...state,
      showReportForm: !state.showReportForm,
    };
  }
  // ----- Upload File to Server (oneDrive) -----
  if (action.type === FILE_UPLOAD_BEGIN) {
    return { ...state, isLoading: true, serverProcessing: true };
  }
  if (action.type === FILE_UPLOAD_SUCCESS) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "success",
      alertText: "Upload to server succesfull!",
      serverProcessing: false,
    };
  }
  if (action.type === FILE_UPLOAD_ERROR) {
    return {
      ...state,
      isLoading: false,
      showAlert: true,
      alertType: "danger",
      alertText: action.payload.msg,
      serverProcessing: false,
    };
  }
  throw new Error(`no such action:`, action.type);
};

export default reducer;
