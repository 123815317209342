import React from "react";
import { useAppContext } from "../context/appContext";

const Alert = () => {
  const { alertType, alertText } = useAppContext();
  return (
    <div name="alert" className={`alert alert-${alertType}`}>
      {alertText}
    </div>
  );
};

export default Alert;
