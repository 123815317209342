import React from "react";
import { numberToMoney } from "../../utils/money";
// import { Text } from "react-native"

const Input = ({
  type,
  name,
  value,
  handleChange,
  labelText,
  dataList,
  checked,
  disabled,
  inputMode,
}) => {
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label">
        {labelText || name}
      </label>
      <input
        type={type || "text"}
        inputMode={inputMode || "text"}
        value={value}
        name={name}
        onChange={handleChange}
        className="form-input"
        list={dataList?.id}
        checked={checked || false}
        {...(disabled && { disabled: true })}
      />
      {dataList && (
        <datalist id={dataList.id}>
          {dataList.options &&
            dataList.options.map((option) => (
              <option value={option.text} key={option.key}>
                {option.text}
              </option>
            ))}
        </datalist>
      )}
    </div>
  );
};
const TextArea = ({ name, value, handleChange, labelText }) => {
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label">
        {labelText || name}
      </label>
      <textarea
        name={name}
        onChange={handleChange}
        className="form-textarea"
        value={value}
      ></textarea>
    </div>
  );
};
const Select = ({ name, handleChange, labelText, value, options }) => {
  // options must be an array of objects with key and text for keys [{key:0 , text:'name'}]
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label">
        {labelText || name}
      </label>
      <select
        name={name}
        id={name}
        value={value ?? "default"} // use nullish coalescing operator to accomidate id's with value 0
        onChange={handleChange}
        className="form-select"
      >
        <option value={"default"} disabled hidden>
          {"Please select..."}
        </option>
        {options &&
          options.map((option) => {
            const { key, text, id } = option;
            return (
              <option value={key || id} key={key || id}>
                {text}
              </option>
            );
          })}
      </select>
    </div>
  );
};
const Display = ({ name, label, value }) => {
  return (
    <div name={name} className="form-row display flex-parent">
      <label>{label || name}:&nbsp;</label>
      <div className="value">&nbsp;{value}</div>
    </div>
  );
};
const StackedDisplay = ({ name, label, value }) => {
  return (
    <div className="form-row display flex-parent">
      <div className="value whole">{value}</div>
      <label className="form-label whole">{label || name}</label>
    </div>
  );
};
const Display1 = ({ name, label, value }) => {
  return (
    <div name={name} className="form-row display">
      <label htmlFor={name} className="form-label">
        {label || name}
      </label>
      <div className="value" style={{ whiteSpace: "pre-wrap" }}>
        {value}
      </div>
    </div>
  );
};
const DisplayMoney = ({ name, label, value }) => {
  // check money value is formatted properly
  const displayValue =
    typeof value === "string" && value.includes("$")
      ? value
      : numberToMoney(value);
  // convert non-money values to string of text("custom")
  const customPrice = displayValue.includes("NaN") && "custom";
  return (
    <div className="form-row display flex-parent money">
      <label className="one-half">{label || name}:</label>&nbsp;
      <div name={name} className="value one-half">
        {customPrice || displayValue}
      </div>
    </div>
  );
};
const DisplayJustValue = ({ value }) => {
  return (
    <div className="form-row display one-half">
      <div className="title">{value}</div>
    </div>
  );
};
const SimpleInputCheckBox = ({
  name,
  checkName,
  value,
  handleCheckboxChange,
  handleTextChange,
  label,
  style,
}) => {
  return (
    <div className="input-checkbox">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <input
        type="checkbox"
        name={checkName || "name"}
        onChange={handleCheckboxChange}
        className="form-input"
        checked={value ? "checked" : ""}
      />
      <input
        type="text"
        value={value}
        name={name}
        style={style}
        onChange={handleTextChange}
        className="form-input text-box"
      />
    </div>
  );
};
const InputCheckBox = ({
  name,
  checkName,
  value,
  textValue,
  handleCheckboxChange,
  handleTextChange,
  label,
  style,
}) => {
  return (
    <div className="form-row">
      <input
        type="checkbox"
        name={checkName || "name"}
        onChange={handleCheckboxChange}
        className="form-input"
        checked={value ? "checked" : ""}
      />
      <input
        type="text"
        value={textValue}
        name={name}
        style={style}
        onChange={handleTextChange}
        className="form-input text-box"
      />
      {label && (
        <label htmlFor={name} className="form-label one-half">
          {label}
        </label>
      )}
    </div>
  );
};
const Checkbox = ({
  type,
  name,
  handleChange,
  labelText,
  dataList,
  checked,
}) => {
  return (
    <div className="form-row">
      <input
        type={type || "text"}
        name={name}
        onChange={handleChange}
        className="form-input"
        list={dataList?.id}
        checked={checked}
      />
      <label htmlFor={name} className="form-label">
        {labelText || name}
      </label>
    </div>
  );
};
const CheckboxButton = ({
  type,
  name,
  handleChange,
  labelText,
  dataList,
  checked,
}) => {
  return (
    <div
      className={
        checked
          ? "checked whole checkbox outline-box"
          : "whole checkbox outline-box"
      }
      onClick={handleChange}
    >
      <input
        type={type || "text"}
        name={name}
        onChange={handleChange}
        className="form-input"
        list={dataList?.id}
        checked={checked}
      />
      <label htmlFor={name} className="form-label">
        {labelText || name}
      </label>
    </div>
  );
};
const RadioPickButton = ({
  name,
  value,
  handleChange,
  labelText,
  options,
  buttonClasses,
}) => {
  return (
    <div className="form-row input-control pick-btn">
      <label htmlFor={name} className="form-label">
        {labelText || name}
      </label>
      <div className="flex-parent whole btn-block">
        {/* map through options to generate buttons */}
        {options?.map((listItem) => {
          return (
            <button
              className={
                parseInt(value) === parseInt(listItem.id)
                  ? `selected btn ${buttonClasses}`
                  : `btn ${buttonClasses}`
              }
              type="button"
              name={name}
              key={listItem.id}
              value={listItem.id}
              onClick={handleChange}
            >
              {listItem.text}
            </button>
          );
        })}
      </div>
    </div>
  );
};
export {
  Input,
  TextArea,
  Select,
  Display,
  StackedDisplay,
  Display1,
  DisplayJustValue,
  DisplayMoney,
  InputCheckBox,
  Checkbox,
  CheckboxButton,
  SimpleInputCheckBox,
  RadioPickButton,
};
