import { useAppContext } from "../../context/appContext";
import { Display, Display1 } from "../dashboard";
import { FaTimes } from "react-icons/fa";

function CustomerMatchModal2({ toggleModal }) {
  const {
    isLoading,
    job,
    customerMatchInfo,
    handleChanges,
    getRecord,
    getArrayRecord,
    saveRecord,
  } = useAppContext();
  const { matchingCustomer, matchingContacts } = customerMatchInfo;

  const contactsString = matchingContacts?.reduce((str, cnt) => {
    if (cnt.is_active)
      return (
        str +
        `${cnt.contact_name} - ${cnt.email || "no email"} - ${
          cnt.phone || "no phone"
        }` +
        "\n"
      );
  }, "");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Update selected customer
    const firstActiveContact = matchingContacts.filter(
      (cnt) => cnt.is_active
    )[0];
    console.log("firstActiveContact:", firstActiveContact);
    const custId = matchingCustomer.id;
    let newValues = {
      customer_id: custId,
      default_contact: firstActiveContact.id,
    };
    handleChanges({ baseObject: job, baseName: "job", newValues });
    const updatedJob = { ...job, newValues };
    await saveRecord(`/core-job/update/${job.id}`, updatedJob, "job");
    // Load matching customer
    getRecord(`/customer/${custId}`, "customer", "customer");
    // Load corresponding contacts
    getArrayRecord(`/contact/${custId}`, "contacts", "contact");
    // close form
    toggleModal();
  };
  return (
    <form className="form email-report-form">
      {/* Close Button */}
      <button className="close-btn" type="button" onClick={toggleModal}>
        <FaTimes />
      </button>
      {/* Prompt Message */}
      <div className="form-center sub-item confirm-report">
        <div className="">
          <h3>Assign this customer to the current job?</h3>
        </div>
      </div>
      {/* Customer */}
      <Display
        name="customer_name"
        label="Customer"
        value={matchingCustomer?.customer_name}
      />
      {/* Contacts */}
      <Display1
        name="Contacts"
        label="Acive Contacts:"
        value={contactsString || ""}
        handleChange={() => {}}
      />

      {/* Submit/ Clear Buttons */}
      <div className="btn-container flex-parent whole bottom-buttons">
        <div className="one-half">
          <button
            name="useAddress"
            className="btn btn-block"
            type="button"
            disabled={isLoading}
            onClick={(e) => handleSubmit(e)}
          >
            {isLoading ? "please wait..." : "Select Customer"}
          </button>
        </div>
        <div className="one-half">
          <button
            name="cancelAddress"
            className="btn btn-block red"
            type="button"
            disabled={isLoading}
            onClick={toggleModal}
          >
            cancel
          </button>
        </div>
      </div>
    </form>
  );
}
export default CustomerMatchModal2;
