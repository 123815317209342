const Loading = () => {
  return (
    <div
      className="loading"
      // className={center ? "loading loading-center" : "loading"}
    ></div>
  );
};

export default Loading;
