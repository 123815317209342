import Contact from "./NewContact";
import { useState } from "react";
import { useAppContext } from "../../context/appContext";

function NewContact() {
  const {
    newContacts,
    newCustomer,
    handlePrimativeChange,
    isLoading,
    displayAlert,
    getArrayRecord,
    handleArrayChanges,
    createRecord,
    saveRecord,
  } = useAppContext();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const allValuesPresent = newContacts.every(
      (contact) => contact.contact_name
    );
    if (!allValuesPresent) {
      displayAlert("Please include a name for every contact");
      return;
    }
    // // save new contacts
    for (const [index, contact] of newContacts.entries()) {
      // create new contact if needed
      if (!contact.id) {
        const record = await createRecord(
          "/contact/create",
          contact,
          "Contact"
        );
      } else {
        // update existing contact
        await saveRecord(`/contact/update/${contact.id}`, contact, "Contact");
      }
    } // );
    // update state
    await getArrayRecord(`/contact/${newCustomer.id}`, "newContacts");
  };
  const addNewContact = (e) => {
    e.preventDefault();
    if (!newCustomer.id) {
      displayAlert("Please select a customer");
      return;
    }
    handleArrayChanges({
      baseName: "newContacts",
      baseArray: [...newContacts],
      index: newContacts.length || 0,
      newValues: { customer_id: newCustomer.id, is_active: true },
    });
  };
  const removeContact = (e) => {
    e.preventDefault();
    const lastContact = newContacts.length - 1;
    const fewerContacts = [...newContacts].slice(0, lastContact);

    handlePrimativeChange({
      property: "newContacts",
      value: fewerContacts,
    });
  };
  const newestContact = newContacts[newContacts?.length - 1]?.contact_name;
  return (
    <div name="newContacts" className="group two-thirds">
      {/* Show contacts section if a customer is loaded */}
      {newCustomer.id ? (
        <>
          <form onSubmit={handleSubmit} className="form form-center">
            <h3>Contacts</h3>
            <div name="contactsList" className="subform contacts">
              {newContacts.length > 0
                ? // display the contacts in reverse order
                  newContacts.map((person, index) => {
                    return (
                      <Contact
                        key={index}
                        index={newContacts.length - index - 1}
                      />
                    );
                  })
                : ""}
            </div>
            <div className="one-half">
              <button
                name="saveContacts"
                className="btn btn-block"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "please wait..." : "Save Contacts"}
              </button>
            </div>
            {/* Show the "add contact" or "remove blank" button */}
            <div className="one-half">
              {!Boolean(newestContact) && newContacts?.length > 0 ? (
                <button
                  name="removeContact"
                  className="btn btn-block red"
                  disabled={isLoading}
                  onClick={removeContact}
                >
                  {isLoading ? "please wait..." : "Remove Blank Contact"}
                </button>
              ) : (
                <button
                  name="newContact"
                  className="btn btn-block"
                  disabled={isLoading}
                  onClick={addNewContact}
                >
                  {isLoading ? "please wait..." : "New Contact"}
                </button>
              )}
            </div>
          </form>
        </>
      ) : (
        <>
          <h3>Please select a customer to continue...</h3>
        </>
      )}
    </div>
  );
}
export default NewContact;
